// Process.js
import React, { useEffect, useRef, useState } from 'react';
import "./process.css";
import star from "../../media/Star.svg";
import thumb from "../../media/money.png";
import coin from "../../media/coins.png";
import laptop from "../../media/laptop.png";

const Process = () => {
  const step1Ref = useRef();
  const step2Ref = useRef();
  const step3Ref = useRef();
  const step4Ref = useRef();

  const [step1Animated, setStep1Animated] = useState(false);
  const [step2Animated, setStep2Animated] = useState(false);
  const [step3Animated, setStep3Animated] = useState(false);
  const [step4Animated, setStep4Animated] = useState(false);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer1 = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setStep1Animated(true);
        observer1.unobserve(step1Ref.current);
      }
    }, observerOptions);

    const observer2 = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setStep2Animated(true);
        observer2.unobserve(step2Ref.current);
      }
    }, observerOptions);

    const observer3 = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setStep3Animated(true);
        observer3.unobserve(step3Ref.current);
      }
    }, observerOptions);

    const observer4 = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setStep4Animated(true);
        observer4.unobserve(step4Ref.current);
      }
    }, observerOptions);

    if (step1Ref.current) {
      observer1.observe(step1Ref.current);
    }
    if (step2Ref.current) {
      observer2.observe(step2Ref.current);
    }
    if (step3Ref.current) {
      observer3.observe(step3Ref.current);
    }
    if (step4Ref.current) {
      observer4.observe(step4Ref.current);
    }

    return () => {
      observer1.disconnect();
      observer2.disconnect();
      observer3.disconnect();
      observer4.disconnect();
    };
  }, []);

  return (
    <div className='process section' id='process'>
      <div className="step1 step" ref={step1Ref}>
        <div className='heading-div'>
          <h2 className={`step1h2 ${step1Animated ? 'animated' : ''}`}>The Process Behind Our Marketing Magic 🪄</h2>
        </div>
        <div className="mission">
          <div className="desc">
            <h2 className={`h1 ${step1Animated ? 'animated' : ''}`}>MISSION 🎯</h2>
            <p className={`h25 ${step1Animated ? 'animated' : ''}`}>“B Rich is defining methods and mechanism of social engineering for companies who are interested in increasing their revenue and reach of their products in unknown territories which they could not penetrate.”</p>
            
          </div>
          <div className="star">
            <img src={star} alt="" />
          </div>
        </div>
      </div>
      <div className="process">
        <div className="step1 step2" ref={step2Ref}>
          <div className="develop">
            <div className={`thumb `} >
              <img src={thumb} alt="" />
            </div>
            <div className="desc desc1">
              <h2 className={`h2 ${step2Animated ? 'animated' : ''}`}>DEVELOP 👨🏻‍💻</h2>
              <p className={`develop-p p2 ${step2Animated ? 'animated' : ''}`}>
                “Where innovation meets your business”
              </p>
              <h5 className={`h25 ${step2Animated ? 'animated' : ''}`}>
                We shine as a beacon of transformation. We grasp the essence of the modern market and recognize the urgent need for a fresh approach that cuts through the noise. Our innovative marketing solutions are not just answers; they’re catalysts for change.
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div className="process">
        <div className="step1 step3" ref={step3Ref}>
          <div className="design">
            <div className="desc desc2">
              <h2 className={`h3 ${step3Animated ? 'animated' : ''}`}>DESIGN 🎨</h2>
              <p className={`develop-p p3 ${step3Animated ? 'animated' : ''}`}>
                “Giving your thoughts and ideas a place to be tailored.”
              </p>
              <h5 className={`h35 ${step3Animated ? 'animated' : ''}`}>
                Our tailored strategies address today’s unique challenges, providing a clear path to differentiation and revitalization. Amid the complexity, we offer clarity.
              </h5>
            </div>
            <div className="coins">
              <img src={coin} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="process">
        <div className="step1 step4" ref={step4Ref}>
          <div className="implement">
            <div className="laptop">
              <img src={laptop} alt="" />
            </div>
            <div className="desc">
              <h2 className={`h4 ${step4Animated ? 'animated' : ''}`}>IMPLEMENT🌟</h2>
              <p className={`develop-p p4 ${step4Animated ? 'animated' : ''}`}>
                “Transforming insights into impact”
              </p>
              <h5 className={`h45 ${step4Animated ? 'animated' : ''}`}>
                Our innovative marketing solutions are not just answers; they’re catalysts for change. We’re here to bridge the gap between your brand’s stagnant present and the dynamic future it truly deserves. With B Rich, your brand discovers a revitalizing force that fuels growth, resonates with audiences, and drives success.
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Process;

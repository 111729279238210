import { React, useState, useEffect } from 'react'
import phone from "../media/phone.svg"
import phoneleft from "../media/phoneleft.png"
import phoneright from "../media/phoneright.png"
import arrow from "../media/arrow.svg"
import send from "../media/send.svg";
import x from "../media/x.svg";
import Submittedpopup from '../components/Submittedpopup'
import SubmittedpopupBaner from '../components/SubmitpopupBanner'



const CBanner = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [showThankYouPopup, setShowThankYouPopup] = useState(false);

    useEffect(() => {
        const html = document.getElementsByTagName('body')[0]
      
        if (showPopup) {
          html.classList.add('open')
        } else {
          html.classList.remove('open')
        }
        return () => {
          html.classList.remove('open')
        }
      }, [showPopup])
  
  
    const handleRequestCallback = () => {
      setShowPopup(true);
    };
  
    const handleFormSubmit = () => {
      // You can add form validation and submission logic here.
      // For now, just set the showThankYouPopup to true.
      setShowThankYouPopup(true);
    };
    return (
        <div className='banner ' >
            <div className="inner-banner">
                <div className="inner-text inner-text1">

                    <div className="para">Amidst a sea of creators, it's becoming increasingly challenging to
                        make your mark and turn your passion into profit. B Rich steps in
                        as the solution, merging innovation with revenue generation, so you
                        can break free from the struggle of unnoticed brilliance and
                        unfulfilled potential. Your creativity deserves to shine –
                        let's make it happen.</div>
                    <div className="head head1">Elevate Your 
                        <span> Creativity</span>
                        <br />
                        with Profitable <br />Innovation.
                    </div>
                </div>

            </div>
            <div className="under">
                <p>Our innovative ideas and concepts will create your identity in virtual world and enhance your business opportunities to generate more business</p>
            </div>
            <div className="speaker">
                <img className='phoneleft' src={phoneleft} alt="" />
                <img className='phoneright' src={phoneright} alt="" />

            </div>
            <div className="request" onClick={handleRequestCallback}>
                Request a Callback
                <div className="images">
                    <img src={phone} alt="" />
                    <img className='arrow' src={arrow} alt="" />

                </div>

            </div>
            <div className="popup-container">
         {showPopup && <SubmittedpopupBaner onClose={() => setShowPopup(false)} handleFormSubmit={handleFormSubmit} where={"Creators Homepage"} />}
        </div>

        </div>
    )
}

export default CBanner

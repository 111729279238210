import { React, useRef, useState, useEffect } from 'react'
import Accordian from '../../components/Accordian/Accordian';
import social from "../../media/moni.svg"
import CContactus from '../CContactus';
import CNavbar from '../Cnavbar';
import CFooter from '../CFooter';
import Loaders from '../../components/Loader';

const Moneytization = () => {
    const accordionItems = [
        {
            question: 'What are "Monetization Strategies," and how can they benefit creators, brands, and individuals in the digital landscape?',
            answer: `"Monetization Strategies" involve crafting customized approaches to transform creative passions into sustainable earnings. They benefit creators, brands, and individuals by providing guidance on optimizing revenue, launching new products, and exploring innovative income channels, ultimately leading to financial success.`,
        },
        {
            question: 'How do "Monetization Strategies" differ from standard monetization techniques available online?',
            answer: `"Monetization Strategies" are tailored to each client's unique goals and creative endeavors. They go beyond generic monetization techniques to provide personalized guidance on how to transform creative ideas into a thriving business with sustainable revenue streams.`,
        },
        {
            question: 'Who can benefit from "Monetization Strategies" services, and in what situations are they most valuable?',
            answer: `"Monetization Strategies" can benefit a wide range of clients, including creators, brands, and individuals looking to turn their creative passions into a source of income. They are most valuable when seeking guidance on optimizing revenue, exploring new monetization avenues, and achieving financial success.`,
        },
        {
            question: 'What specific strategies are employed in "Monetization Strategies" to help clients transform their creative passion into earnings?',
            answer: `"Monetization Strategies" encompass a variety of approaches, including ad revenue optimization, product launches, affiliate marketing, content monetization, and exploring innovative income channels. These strategies are customized to match each client's goals and creativity.`,
        },
        {
            question: 'How can "Monetization Strategies" help clients unlock the full potential of their creativity while achieving financial success?',
            answer: `"Monetization Strategies" provide clients with guidance and support on generating sustainable earnings from their creative endeavors. By optimizing revenue, launching new products, and exploring innovative income channels, clients can transform their ideas into a thriving business while maintaining their creative passion.`,
        },
    ];
    const faqHeadRef = useRef();
    const [faqHeadAnimated, setFaqHeadAnimated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setFaqHeadAnimated(true);
                observer.unobserve(faqHeadRef.current);
            }
        }, observerOptions);

        if (faqHeadRef.current) {
            observer.observe(faqHeadRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [isLoading]);

    useEffect(() => {
      // Simulate a loading delay for demonstration purposes
      setTimeout(() => {
        setIsLoading(false); // Set isLoading to false when everything is loaded
      }, 3000);
    }, []);
    return (
        <>
        {isLoading ? (
            <Loaders />
          ) : (
            <>
      <CNavbar/>        <div div className='outer'>
            <div className='general'>
                <div className="info">
                    <div className="info-head">Monetization Strategies </div>
                    <div className="info-d">“Creativity meets revenue. Our tailored monetization 
strategies empower you to transform your passion into earnings. From ad revenue optimization to product launches, we guide you towards sustainable financial success.
”</div>
                    <div className="info-p">Discover 'Monetization Strategies,' where we fuse creativity with sustainable revenue streams. Our customized strategies empower you to turn your passion into a profitable venture. Whether it's optimizing ad revenue, launching new products, or exploring innovative income channels, we're your trusted partner in the journey toward financial success. Let's transform your ideas into a thriving business and unlock the full potential of your creativity.</div>

                </div>
                <div className="service-image">
                    <div className="background">
                        <img className='slide-img' src={social} alt="" />

                    </div>

                </div>



            </div>
            <div className='faq'>
                <div className="inner-faq">
                    <div className="faq-head" ref={faqHeadRef}>
                        <h2 className={`faq-h2 ${faqHeadAnimated ? 'animated' : ''}`}>FAQ’S</h2>
                        <h3 className={`faq-h4 ${faqHeadAnimated ? 'animated' : ''}`}>Frequently asked questions</h3>
                        <p className={`faq-h5 ${faqHeadAnimated ? 'animated' : ''}`}>Can’t find the answer you’re looking for? Fill the Contact Form Below, Our Team will reach out to you soon.</p>
                    </div>
                    <div className="accordian">
                        <Accordian data={accordionItems} />
                    </div>
                </div>
            </div>
            <CContactus where={"Monetization Strategies"} />
        </div>
        <CFooter/>
                </>
                )}
                </>
    )
}

export default Moneytization

import { React, useState, useEffect } from 'react'
import CBanner from '../CBanner'
import CDiscover from '../CDiscover'
import CProcess from '../Cprocess'
import Brands from '../../components/Brands/Brands'
import CFaq from '../CFaq'
import CContactus from '../CContactus'
import CNavbar from '../Cnavbar'
import CFooter from '../CFooter'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import Loader from '../../components/Loader'
import ScrollButton from '../../components/Scroll to top/ScrollButton'



const CHomepage = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false); 
    }, 4000);
  }, []);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="smooth">

            <Fullpage scrollSpeed={700} duration={700} scrollSnapType={"smooth"} >
              <FullPageSections  style={{scrollSnapType:"y"}}>

                <FullpageSection style={{ overflowY: "scroll", display: "flex", alignItems: "center", flexDirection: "column", height: "100vh" }}>
                  <CNavbar />
                  <CBanner />
                </FullpageSection>

                <FullpageSection style={{ overflowY: "scroll", height: "100vh" }}>
                  <CDiscover style={{ height: "100vh" }} className='section2' />

                </FullpageSection >

                <FullpageSection style={{ overflowY: "scroll", height: "100vh ",scrollSpeed:"500" }}>

                  <CProcess />

                </FullpageSection>

                <FullpageSection style={{ overflowY: "scroll", height: "100vh" }}>
                  <Brands  style={{ height: "100vh" }}/>

                </FullpageSection>

                <FullpageSection style={{ overflowY: "scroll", height: "100vh" }}>
                  <CFaq style={{ height: "100vh" }}/>

                </FullpageSection>

                <FullpageSection style={{ overflowY: "scroll", height: "100vh" }}>

                  <CContactus where={'Creators Homepage'}/>
                  <CFooter />
                </FullpageSection>
              </FullPageSections>
            </Fullpage>
           <ScrollButton/>
          </div>
          <div className="normal" style={{scrollSpeed:"500"}}>
            <CNavbar />
            <CBanner />
            <CDiscover />
            <CProcess />
            <Brands />
            <CFaq />
            <CContactus where={'Creators Homepage'} />
            <CFooter />
            {/* <ScrollButtonNormal/> */}

          </div>

        </>

      )}
    </>
  )
}

export default CHomepage

import { React, useRef, useState, useEffect } from 'react'
import "./Allservice.css"
import Accordian from '../Accordian/Accordian';
import Contactus from '../Contact us/Contactus';
import social from "../../media/paid.svg"
import aimarket from "../../media/aimarket.png"
import Footer from '../footer/Footer';
import Navbar from '../Navbar/Navbar';
import Loaders from '../Loader';

const Paid = () => {
    const accordionItems = [
        {
            question: 'What is AI Marketing, and how can it benefit my brand or business?',
            answer: `AI Marketing involves strategic campaigns where you invest money to promote your products or services. It benefits your brand by maximizing the impact of every dollar spent, ensuring your message reaches the right audience at the right time, leading to increased brand visibility and conversions.`,
        },
        {
            question: 'How can AI Marketing campaigns maximize the impact of my advertising budget?',
            answer: `We achieve budget optimization through data-driven insights and strategic ad placements. By analyzing audience behavior and trends, we can target your ads to the most relevant and receptive audience, ensuring that your budget is spent efficiently and effectively.`,
        },
        {
            question: 'What types of businesses or industries can benefit from AI Marketing services?',
            answer: `AI Marketing is versatile and can benefit a wide range of businesses and industries. It's especially valuable for those seeking to boost brand visibility, drive website traffic, generate leads, and increase sales through targeted advertising.`,
        },
        {
            question: 'What sets your AI Marketing services apart from other advertising agencies?',
            answer: `Our AI Marketing services stand out for their precision and data-driven approach. We focus on understanding your specific goals and target audience to create highly targeted campaigns that maximize your return on investment. Our commitment to delivering measurable results sets us apart.`,
        },
        {
            question: 'How can I get started with AI Marketing to make every investment yield measurable returns?',
            answer: `To get started with AI Marketing, reach out to us through our website or contact page. We'll schedule a consultation to discuss your advertising objectives, budget, and target audience. From there, we'll develop a customized strategy that ensures your message reaches the right audience at the most opportune moments.`,
        },
    ];
    const faqHeadRef = useRef();
    const [faqHeadAnimated, setFaqHeadAnimated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setFaqHeadAnimated(true);
                observer.unobserve(faqHeadRef.current);
            }
        }, observerOptions);

        if (faqHeadRef.current) {
            observer.observe(faqHeadRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [isLoading]);

    useEffect(() => {
        // Simulate a loading delay for demonstration purposes
        setTimeout(() => {
            setIsLoading(false); // Set isLoading to false when everything is loaded
        }, 3000);
    }, []);
    return (
        <>
            {isLoading ? (
                <Loaders />
            ) : (
                <>
                    <Navbar />        <div div className='outer'>
                        <div className='general'>
                            <div className="info">
                                <div className="info-head">AI
                                    Marketing</div>
                                <div className="info-d">“Enhance your brand's reach with precision-targeted AI marketing. We harness advanced analytics to connect your message with your ideal audience, maximizing engagement. ”</div>
                                <div className="info-p">Transform your brand's visibility with AI Marketing, engineered for impact. Leveraging machine learning, our campaigns optimize real-time, ensuring your message hits its mark. With predictive analytics and intelligent targeting, we connect your brand to its ideal audience, enhancing engagement at key moments. Experience the future of marketing precision, where every strategy is data-driven and results-oriented.
                                </div>

                            </div>
                            <div className="service-image">
                                <div className="background">
                                    <img className='slide-img' src={aimarket} alt="" />

                                </div>

                            </div>



                        </div>
                        <div className='faq'>
                            <div className="inner-faq">
                                <div className="faq-head" ref={faqHeadRef}>
                                    <h2 className={`faq-h2 ${faqHeadAnimated ? 'animated' : ''}`}>FAQ’S</h2>
                                    <h3 className={`faq-h4 ${faqHeadAnimated ? 'animated' : ''}`}>Frequently asked questions</h3>
                                    <p className={`faq-h5 ${faqHeadAnimated ? 'animated' : ''}`}>Can’t find the answer you’re looking for? Fill the Contact Form Below, Our Team will reach out to you soon.</p>
                                </div>
                                <div className="accordian">
                                    <Accordian data={accordionItems} />
                                </div>
                            </div>
                        </div>
                        <Contactus where={"AI Marketing"} />
                    </div>
                    <Footer />
                </>
            )}
        </>
    )
}

export default Paid

import {React, useState, useEffect} from 'react'
import "./footer.css"
import brich from "../../media/brich.svg";
import  p9 from "../../media/p9.svg"; 
import fb from "../../media/fb.svg";
import li from "../../media/li.svg";
import insta from "../../media/insta.svg";
import yellowfb from "../../media/yellowfb.svg";
import yellowli from "../../media/yellowli.svg";
import yellowinsta from "../../media/yellowinsta.svg";
import send from "../../media/send.svg";
import x from "../../media/x.svg";
import Submittedpopup from '../Submittedpopup';


const Footer = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [showThankYouPopup, setShowThankYouPopup] = useState(false);


  const handleRequestCallback = () => {
    setShowPopup(true);
  };

  useEffect(() => {
    const html = document.getElementsByTagName('body')[0]
  
    if (showPopup) {
      html.classList.add('open')
    } else {
      html.classList.remove('open')
    }
    return () => {
      html.classList.remove('open')
    }
  }, [showPopup])

  const handleFormSubmit = () => {
    // You can add form validation and submission logic here.
    // For now, just set the showThankYouPopup to true.
    setShowThankYouPopup(true);
  };
  const handleIconHover = (event) => {
    const target = event.target;
    const iconName = target.getAttribute("data-icon");

    if (iconName) {
      switch (iconName) {
        case 'insta':
          target.src = yellowinsta;
          break;
        case 'li':
          target.src = yellowli;
          break;
        case 'fb':
          target.src = yellowfb;
          break;
        default:
          break;
      }
    }
  };

  const handleIconLeave = (event) => {
    const target = event.target;
    const iconName = target.getAttribute("data-icon");

    if (iconName) {
      switch (iconName) {
        case 'insta':
          target.src = insta;
          break;
        case 'li':
          target.src = li;
          break;
        case 'fb':
          target.src = fb;
          break;
        default:
          break;
      }
    }
  };
  const handleNavigation = (url) => {
    window.location.href = url; // Redirect to the specified URL
  };
  return (
    <div className='footer section'>
      <div className="inner-foot">

        <div className="service-social">
          <div className='heading'>Services</div>
          <div className="services">

             <div className="first">
        <div className="a" onClick={() => handleNavigation('/social-media-marketing')}>
          Social Media Marketing
        </div>
        <div className="a" onClick={() => handleNavigation('/zig-zag-marketing')}>
          Zig Zag Marketing
        </div>
        <div className="a" onClick={() => handleNavigation('live-management')}>
          Live Management
        </div>
      </div>
      <div className="second">
        <div className="a" onClick={() => handleNavigation('conceptual-marketing')}>
          Conceptual Marketing
        </div>
        <div className="a" onClick={() => handleNavigation('AI-marketing')}>
          AI Marketing
        </div>
        <div className="a" onClick={() => handleNavigation('reach-enhancements')}>
          Reach Enhancements
        </div>
      </div>
      <div className="third">
        <div className="a" onClick={() => handleNavigation('influencer-marketing')}>
          Influencer Marketing
        </div>
        <div className="a" onClick={() => handleNavigation('reputation-management')}>
          Reputation Management
        </div>
        <div className="a" onClick={() => handleNavigation('digital-marketing')}>
          Digital Marketing
        </div>
      </div>
          </div>

        </div>
        <div className="social">
          <div className="us"> Contact us.</div>
          <div className="callback" onClick={handleRequestCallback}><img src={p9} alt="" />Request a Callback</div>
          <div className="icon">
            <a
              href="https://instagram.com/brich.in?igshid=MzRlODBiNWFlZA=="
              className="social-icon"
              onMouseEnter={handleIconHover}
              onMouseLeave={handleIconLeave}
            >
              <img src={insta} alt="Instagram" data-icon="insta" />
            </a>
            <a
              href="https://www.linkedin.com/company/b-rich/"
              className="social-icon"
              onMouseEnter={handleIconHover}
              onMouseLeave={handleIconLeave}
            >
              <img src={li} alt="LinkedIn" data-icon="li" />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61551967450079&mibextid=LQQJ4d"
              className="social-icon"
              onMouseEnter={handleIconHover}
              onMouseLeave={handleIconLeave}
            >
              <img src={fb} alt="Facebook" data-icon="fb" />
            </a>
          </div>
        </div>
        <div className="popup-container">
         {showPopup && <Submittedpopup onClose={() => setShowPopup(false)} handleFormSubmit={handleFormSubmit}  where={"Brands Homepage"} />}
        </div>
        <div className="brand" onClick={() => handleNavigation('/')}>
          <img src={brich} alt="" />
          B RICH
        </div>

      </div>
    </div>
  )
}

export default Footer

import { React, useRef, useState, useEffect } from 'react'
import Accordian from '../../components/Accordian/Accordian';
import social from "../../media/authentic.svg"
import CContactus from '../CContactus';
import CNavbar from '../Cnavbar';
import CFooter from '../CFooter';
import Loaders from '../../components/Loader';

const Authentic = () => {
    const accordionItems = [
        {
            question: 'What is "Authentic Engagement," and how can it benefit creators, brands, and individuals in the digital landscape?',
            answer: `"Authentic Engagement" revolves around creating real-time connections and fostering authentic interactions with your audience. It benefits creators, brands, and individuals by enabling them to form deeper connections with their audience, engage in conversations that matter, and spark meaningful discussions that resonate.`,
        },
        {
            question: 'How does "Authentic Engagement" differ from traditional social media or audience engagement strategies?',
            answer: `"Authentic Engagement" stands out by focusing on real-time interactions and fostering genuine connections. It goes beyond surface-level engagement to create deeper, more meaningful conversations that build trust and rapport with the audience.`,
        },
        {
            question: 'Who can benefit from "Authentic Engagement" services, and in what situations are they most valuable?',
            answer: `"Authentic Engagement" can benefit a wide range of clients, including creators, brands, and individuals looking to enhance their audience engagement and establish a more authentic connection with their viewers or customers. It is especially valuable when the goal is to make every interaction truly count.`,
        },
        {
            question: 'What strategies are employed to create authentic interactions in "Authentic Engagement"?',
            answer: `Strategies involve live engagement during events, webinars, social media interactions, and more. It includes being present in real time to respond to comments, address questions, and actively engage with the audience, ensuring that every interaction is authentic and meaningful.`,
        },
        {
            question: 'What can clients expect from "Authentic Engagement" in terms of the impact of their real-time interactions and discussions with the audience?',
            answer: `Clients can expect deeper connections with their audience, increased trust and engagement, and the ability to spark discussions that truly matter to their viewers or customers. "Authentic Engagement" helps to make every interaction count and enhance the overall quality of audience engagement.`,
        },
    ];
     const faqHeadRef = useRef();
    const [faqHeadAnimated, setFaqHeadAnimated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setFaqHeadAnimated(true);
                observer.unobserve(faqHeadRef.current);
            }
        }, observerOptions);

        if (faqHeadRef.current) {
            observer.observe(faqHeadRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [isLoading]);

    useEffect(() => {
      // Simulate a loading delay for demonstration purposes
      setTimeout(() => {
        setIsLoading(false); // Set isLoading to false when everything is loaded
      }, 3000);
    }, []);
    return (
        <>
        {isLoading ? (
            <Loaders />
          ) : (
            <>
      <CNavbar/>        <div div className='outer'>
            <div className='general'>
                <div className="info">
                    <div className="info-head">Authentic Engagement</div>
                    <div className="info-d">“Connect in real time. Our live engagement strategies create 
authentic interactions that deepen your connection with your audience. Engage in conversations that matter, sparking discussions that resonate.

”</div>
                    <div className="info-p">Welcome to 'Authentic Engagement,' where real-time connections become a game-changer. Our live engagement strategies foster authentic interactions, allowing you to forge deeper connections with your audience. Engage in meaningful conversations, ignite discussions that resonate, and spark genuine connections. It's time to make every moment count with authentic engagement that truly matters</div>

                </div>
                <div className="service-image">
                    <div className="background">
                        <img className='slide-img' src={social} alt="" />

                    </div>

                </div>



            </div>
            <div className='faq'>
                <div className="inner-faq">
                    <div className="faq-head" ref={faqHeadRef}>
                        <h2 className={`faq-h2 ${faqHeadAnimated ? 'animated' : ''}`}>FAQ’S</h2>
                        <h3 className={`faq-h4 ${faqHeadAnimated ? 'animated' : ''}`}>Frequently asked questions</h3>
                        <p className={`faq-h5 ${faqHeadAnimated ? 'animated' : ''}`}>Can’t find the answer you’re looking for? Fill the Contact Form Below, Our Team will reach out to you soon.</p>
                    </div>
                    <div className="accordian">
                        <Accordian data={accordionItems} />
                    </div>
                </div>
            </div>
            <CContactus where={"Authentic Engagement"} />
        </div>
        <CFooter/>
                </>
                )}
                </>
    )
}

export default Authentic

import { React, useState, useEffect } from 'react';
import Banner from '../Bannner/Banner';
import Discover from '../Discover/Discover';
import Process from '../Process/Process';
import Brands from '../Brands/Brands';
import Faq from '../FAQ/Faq';
import Contactus from '../Contact us/Contactus';
import Navbar from '../Navbar/Navbar';
import Footer from '../footer/Footer';
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'
import Loader from '../Loader';
import ScrollButton from '../Scroll to top/ScrollButton';



const Homepage = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay for demonstration purposes
    setTimeout(() => {
      setIsLoading(false); // Set isLoading to false when everything is loaded
    }, 4000);
  }, []);


  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="smooth" >

            <Fullpage scrollSnapType={"smooth"} scrollSpeed={700} duration={700}>
              <FullPageSections style={{ scrollSnapType: "y" }}>
                <FullpageSection style={{ overflowY: "scroll", display: "flex", alignItems: "center", flexDirection: "column" }}>
                  <Navbar />
                  <Banner />
                </FullpageSection>

                <FullpageSection style={{ overflowY: "scroll", height: "100vh" }}>
                  <Discover style={{ height: "100vh" }} />

                </FullpageSection >

                <FullpageSection style={{ overflowY: "scroll", height: "100vh", scrollSpeed: "500" }}>

                  <Process />

                </FullpageSection>

                <FullpageSection style={{ overflowY: "scroll", height: "100vh" }}>
                  <Brands style={{ height: "100vh" }} />

                </FullpageSection>

                <FullpageSection style={{ overflowY: "scroll", height: "100vh" }}>
                  <Faq style={{ height: "100vh" }} />

                </FullpageSection>

                <FullpageSection style={{ overflow: "scroll", height: "103vh", display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>

                  <Contactus where={'Brands Homepage'} />
                  <Footer />
                </FullpageSection>
              </FullPageSections>
            </Fullpage>
            <ScrollButton/>
          </div>
          <div className="normal" style={{ scrollSpeed: "500" }}>
            <Navbar />
            <Banner />
            <Discover />
            <Process />
            <Brands />
            <Faq />
            <Contactus id="contactus" where={'Brands Homepage'} />
            <Footer />
            {/* <ScrollButtonNormal/> */}
          </div>

        </>

      )}
    </>

  );

}

export default Homepage;

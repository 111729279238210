import { React, useRef, useState, useEffect } from 'react'
import Accordian from '../../components/Accordian/Accordian';
import social from "../../media/stratergy.svg"
import CContactus from '../CContactus';
import CNavbar from '../Cnavbar';
import CFooter from '../CFooter';
import Loaders from '../../components/Loader';

const Stratergic = () => {
    const accordionItems = [
        {
            question: 'What are "Strategic Partnerships," and how can they benefit creators, brands, and individuals?',
            answer: `"Strategic Partnerships" involve the orchestration of collaborative relationships that enhance reach and credibility. They benefit creators, brands, and individuals by providing opportunities for mutual growth, expanded influence, and the creation of lasting impact through meaningful partnerships.`,
        },
        {
            question: 'How do "Strategic Partnerships" differ from standard collaborations or partnerships?',
            answer: `"Strategic Partnerships" differ in that they are carefully crafted and tailored to align with specific goals and values. They go beyond one-off collaborations to create long-lasting, impactful relationships that bolster credibility and reach.`,
        },
        {
            question: 'Who can benefit from "Strategic Partnerships" services, and in what situations are they most valuable?',
            answer: `"Strategic Partnerships" are valuable for creators, brands, and individuals looking to maximize the impact of their collaborations. They are especially beneficial when seeking to expand influence, establish credibility, and unlock new opportunities through meaningful partnerships.`,
        },
        {
            question: 'How does your service leverage an extensive network to create impactful collaborations?',
            answer: `We tap into our extensive network to identify potential partners that align with your goals and values. Our approach involves careful planning, negotiation, and strategic alignment to ensure that collaborations are meaningful and mutually beneficial.`,
        },
        {
            question: 'What can clients expect from "Strategic Partnerships" in terms of the impact of their collaborations and mutual growth?',
            answer: `Clients can expect to witness the impact of their collaborations in the form of increased reach, credibility, and influence. "Strategic Partnerships" create opportunities for mutual growth, leaving a lasting imprint in the digital landscape and delivering meaningful results.`,
        },
    ];
     const faqHeadRef = useRef();
    const [faqHeadAnimated, setFaqHeadAnimated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setFaqHeadAnimated(true);
                observer.unobserve(faqHeadRef.current);
            }
        }, observerOptions);

        if (faqHeadRef.current) {
            observer.observe(faqHeadRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [isLoading]);

    useEffect(() => {
      // Simulate a loading delay for demonstration purposes
      setTimeout(() => {
        setIsLoading(false); // Set isLoading to false when everything is loaded
      }, 3000);
    }, []);
    return (
        <>
        {isLoading ? (
            <Loaders />
          ) : (
            <>
      <CNavbar/>        <div div className='outer'>
            <div className='general'>
                <div className="info">
                    <div className="info-head">Strategic Partnerships</div>
                    <div className="info-d">“Collaborations that resonate. We leverage our network to 
forge strategic partnerships that enhance your reach and credibility. Whether it's 
fellow creators or industry leaders, we ensure your collaborations are impactful and mutually beneficial
.”</div>
                    <div className="info-p">Introducing 'Strategic Partnerships,' where the synergy of collaboration transforms possibilities into realities. Our extensive network becomes your greatest asset, as we craft strategic alliances that expand your influence and bolster your credibility. Whether it's with fellow creators or industry pioneers, we orchestrate collaborations that leave a lasting imprint, delivering impact and mutual growth. Step into the world of limitless opportunities through meaningful partnerships.</div>

                </div>
                <div className="service-image">
                    <div className="background">
                        <img className='slide-img' src={social} alt="" />

                    </div>

                </div>



            </div>
            <div className='faq'>
                <div className="inner-faq">
                    <div className="faq-head" ref={faqHeadRef}>
                        <h2 className={`faq-h2 ${faqHeadAnimated ? 'animated' : ''}`}>FAQ’S</h2>
                        <h3 className={`faq-h4 ${faqHeadAnimated ? 'animated' : ''}`}>Frequently asked questions</h3>
                        <p className={`faq-h5 ${faqHeadAnimated ? 'animated' : ''}`}>Can’t find the answer you’re looking for? Fill the Contact Form Below, Our Team will reach out to you soon.</p>
                    </div>
                    <div className="accordian">
                        <Accordian data={accordionItems} />
                    </div>
                </div>
            </div>
            <CContactus  where={"Strategic Partnerships"}/>
        </div>
        <CFooter/>
                </>
                )}
                </>
    )
}

export default Stratergic

import React, { useState } from 'react';
import './accordian.css'; // Import your custom CSS file
import plus from "../../media/plus.svg"
import minus from "../../media/minus.svg"

function Accordian({ data }) {
    const [activeIndex, setActiveIndex] = useState(-1);

    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(-1);
        } else {
            setActiveIndex(index);
        }
    };

    // const accordionItems = [
    //     {
    //         question: 'What sets Brich apart from other marketing agencies?',
    //         answer: `At Brich, we're not just marketers; we're innovators. Our unique blend of conceptual 
    //         strategies, cutting-edge technology, and dynamic approaches differentiate us. 
    //         We challenge the norm, infusing creativity into every campaign to maximize 
    //         your brand's potential.`,
    //     },
    //     {
    //         question: 'How can Brich help my brand overcome stagnant growth?',
    //         answer: `Our services are tailored to break through growth barriers. Through innovative
    //         messaging, targeted campaigns, and live interactions, we revitalize engagement
    //         and rekindle growth where it matters most.`,
    //     },
    //     {
    //         question: `Can Brich enhance my brand's online reputation`,
    //         answer: `Absolutely. Our comprehensive reputation management strategies not only protect
    //         your brand from negative feedback but also proactively shape a positive online
    //         presence, building trust and credibility.`,
    //     },
    //     {
    //         question: ' What benefits does influencer marketing offer my brand?',
    //         answer: `Influencer marketing amplifies your brand's reach through authentic connections.
    //         We collaborate with influencers aligned with your values, fostering real engagement
    //         and driving meaningful results.`,
    //     },
    // ];

    return (
            <div className="accordion-container">
                <div className='accordian-item'>
                    {data.map((item, index) => (
                        <div
                            key={index}
                            className={`accordion-item ${index === activeIndex ? 'active' : ''}`}
                            onClick={() => toggleAccordion(index)}
                        >
                            <div className="accordion-question">
                                {item.question}
                                <span className="accordion-icon">
                                    {index === activeIndex ? (
                                        <img src={minus} alt="Minus" /> // Use the minus image
                                    ) : (
                                        <img src={plus} alt="Plus" /> // Use the plus image
                                    )}
                                </span>
                            </div>
                            {index === activeIndex && (
                                <div className="accordion-answer">{item.answer}</div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
    );
}

export default Accordian;

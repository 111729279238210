import { React, useState, useEffect } from 'react'
import "./banner.css"
import phone from "../../media/phone.svg"
import phoneleft from "../../media/phoneleft.png"
import phoneright from "../../media/phoneright.png"
import arrow from "../../media/arrow.svg"
import send from "../../media/send.svg";
import x from "../../media/x.svg";
import SubmittedpopupBaner from '../SubmitpopupBanner'



const Banner = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [showThankYouPopup, setShowThankYouPopup] = useState(false);
    useEffect(() => {
        const html = document.getElementsByTagName('body')[0]
      
        if (showPopup) {
          html.classList.add('open')
        } else {
          html.classList.remove('open')
        }
        return () => {
          html.classList.remove('open')
        }
      }, [showPopup])
  
  
    const handleRequestCallback = () => {
      setShowPopup(true);
    };
  
    const handleFormSubmit = () => {
      // You can add form validation and submission logic here.
      // For now, just set the showThankYouPopup to true.
      setShowThankYouPopup(true);
    };
    return (
        <div className='banner ' >
            <div className="inner-banner in1">
                <div className="inner-text inner1">

                    <div className="para">We are in an era where traditional marketing media and books no longer hold the monopoly of marketing. B Rich is redefining the existing marketing strategies with its own new concepts, assembling with new innovations and cutting-edge technologies to erase the boundaries of traditional marketing.  </div>
                    <div className="head">Art of 
                         <span> Social</span>
                        <br />
                        Engineering .</div>
                </div>

            </div>
            <div className="under">
                <p>Social engineering is an art of thoughts, which is merged with the visual world and real world through various new concepts and innovative technologies which are defining new territories and boundaries to reach the clients and increase the revenue share of companies</p>
            </div>
            <div className="speaker">
                <img className='phoneleft' src={phoneleft} alt="" />
                <img className='phoneright' src={phoneright} alt="" />

            </div>
            <div className="request" onClick={handleRequestCallback}>
                Request a Callback
                <div className="images">
                    <img src={phone} alt="" />
                    <img className='arrow' src={arrow} alt="" />

                </div>

            </div>
            <div className="popup-container">
         {showPopup && <SubmittedpopupBaner onClose={() => setShowPopup(false)} handleFormSubmit={handleFormSubmit} where={"Brands Homepage"} />}
        </div>

        </div>
    )
}

export default Banner

import { React, useRef, useState, useEffect } from 'react'
import Accordian from '../../components/Accordian/Accordian';
import social from "../../media/influencer.svg"
import CContactus from '../CContactus';
import CNavbar from '../Cnavbar';
import CFooter from '../CFooter';
import Loaders from '../../components/Loader';

const Influence = () => {
    const accordionItems = [
        {
            question: 'What is "Influence Amplification," and how can it benefit creators and influencers?',
            answer: `"Influence Amplification" involves harnessing the power of influence to elevate creators and influencers, positioning them as authorities in their niches. It benefits creators by expanding their reach, credibility, and influence, opening doors to meaningful collaborations and opportunities.`,
        },
        {
            question: 'How do you amplify influence and help creators rise above the noise in their respective niches?',
            answer: `We amplify influence by implementing a range of strategies, including content optimization, audience engagement, branding, and reputation management. These strategies work together to elevate a creator's presence, ensuring their message resonates far and wide.`,
        },
        {
            question: 'What types of creators and influencers can benefit from "Influence Amplification" services?',
            answer: `"Influence Amplification" can benefit a wide variety of creators, from content creators and social media influencers to experts in specific industries. It's ideal for those looking to establish themselves as authoritative voices and maximize their impact.`,
        },
        {
            question: 'How do meaningful collaborations and opportunities arise from amplified influence?',
            answer: `Amplified influence often attracts the attention of brands, organizations, and other influencers who recognize the value of collaborating with authoritative voices. These collaborations can lead to partnerships, sponsorships, speaking engagements, and other opportunities that reflect the creator's newfound status.`,
        },
        {
            question: 'What can creators and influencers expect after utilizing "Influence Amplification" services?',
            answer: `Creators and influencers can expect enhanced recognition, a broader and more engaged audience, and the opportunity to position themselves as authorities in their respective niches. "Influence Amplification" can be a transformative journey that leads to meaningful growth and recognition.`,
        },
    ];
    const faqHeadRef = useRef();
    const [faqHeadAnimated, setFaqHeadAnimated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setFaqHeadAnimated(true);
                observer.unobserve(faqHeadRef.current);
            }
        }, observerOptions);

        if (faqHeadRef.current) {
            observer.observe(faqHeadRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [isLoading]);

    useEffect(() => {
      // Simulate a loading delay for demonstration purposes
      setTimeout(() => {
        setIsLoading(false); // Set isLoading to false when everything is loaded
      }, 3000);
    }, []);
    return (
        <>
        {isLoading ? (
            <Loaders />
          ) : (
            <>
      <CNavbar/>        <div div className='outer'>
            <div className='general'>
                <div className="info">
                    <div className="info-head">Influence Amplification </div>
                    <div className="info-d">“Rise above the noise. With B Rich, your influence becomes a 
force to reckon with. We amplify your voice, positioning you as an authority in your niche and opening doors to meaningful opportunities.
”</div>
                    <div className="info-p">Experience 'Influence Amplification,' where your voice transcends the noise. At B Rich, we harness the power of influence, transforming you into an industry authority. Our strategies elevate your presence, ensuring your message resonates far and wide. Prepare to unlock doors to meaningful collaborations and opportunities that reflect your newfound status. Your journey to influence begins here.</div>

                </div>
                <div className="service-image">
                    <div className="background">
                        <img className='slide-img' src={social} alt="" />

                    </div>

                </div>



            </div>
            <div className='faq'>
                <div className="inner-faq">
                    <div className="faq-head" ref={faqHeadRef}>
                        <h2 className={`faq-h2 ${faqHeadAnimated ? 'animated' : ''}`}>FAQ’S</h2>
                        <h3 className={`faq-h4 ${faqHeadAnimated ? 'animated' : ''}`}>Frequently asked questions</h3>
                        <p className={`faq-h5 ${faqHeadAnimated ? 'animated' : ''}`}>Can’t find the answer you’re looking for? Fill the Contact Form Below, Our Team will reach out to you soon.</p>
                    </div>
                    <div className="accordian">
                        <Accordian data={accordionItems} />
                    </div>
                </div>
            </div>
            <CContactus where={"Influence Amplification "}  />
        </div>
        <CFooter/>
                </>
                )}
                </>
    )
}

export default Influence

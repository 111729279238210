import { React, useRef, useState, useEffect } from 'react'
import "./Allservice.css"
import Accordian from '../Accordian/Accordian';
import Contactus from '../Contact us/Contactus';
import social from "../../media/influence.svg"
import Footer from '../footer/Footer';
import Navbar from '../Navbar/Navbar';
import Loaders from '../Loader';

const Influencer = () => {
    const accordionItems = [
        {
            question: 'What is Influencer Marketing, and how can it benefit my brand or business?',
            answer: `Influencer Marketing is a strategy that involves partnering with individuals who have a significant following or influence within your target audience. It benefits your brand by leveraging the trust and credibility these influencers have built, which can result in increased brand visibility, engagement, and conversions.`,
        },
        {
            question: `How do you choose the right influencers for a brand's marketing campaign?`,
            answer: `We carefully select influencers whose values, audience, and niche align with your brand's goals. The goal is to create authentic partnerships where the influencer's content and messaging resonate with your target audience, ensuring that the collaboration is a genuine reflection of your brand values.`,
        },
        {
            question: 'What types of businesses or industries can benefit from Influencer Marketing services?',
            answer: `Influencer Marketing is adaptable and can benefit a wide range of businesses and industries. It's particularly effective for those seeking to connect with their audience in a more authentic and engaging way, regardless of the industry they operate in.`,
        },
        {
            question: 'How does authenticity play a role in your Influencer Marketing approach?',
            answer: `Authenticity is a cornerstone of our approach. We ensure that every collaboration between your brand and influencers reflects your brand values and resonates with the audience. This genuine approach helps build trust and credibility, making the marketing campaign more effective.`,
        },
        {
            question: 'What kind of results can a brand expect from an Influencer Marketing campaign?',
            answer: `Brands can expect increased brand awareness, engagement, and conversions. The specific results may vary depending on the campaign's goals, but Influencer Marketing is known for its ability to drive tangible results, thanks to the trust and connection influencers have with their followers.`,
        },
    ];
     const faqHeadRef = useRef();
    const [faqHeadAnimated, setFaqHeadAnimated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setFaqHeadAnimated(true);
                observer.unobserve(faqHeadRef.current);
            }
        }, observerOptions);

        if (faqHeadRef.current) {
            observer.observe(faqHeadRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [isLoading]);

    useEffect(() => {
      // Simulate a loading delay for demonstration purposes
      setTimeout(() => {
        setIsLoading(false); // Set isLoading to false when everything is loaded
      }, 3000);
    }, []);
    return (
        <>
        {isLoading ? (
            <Loaders />
          ) : (
            <>
            <Navbar/>        <div div className='outer'>
            <div className='general'>
                <div className="info">
                    <div className="info-head">Influencer Marketing </div>
                    <div className="info-d">“Influencer marketing allows brand to reach their target demographic customers through handily picked influencer who resonate their ethos

”</div>
                    <div className="info-p">Small and massive companies are now switching towards Influencer marketing partnerships, which are seriously giving more results than the stalwart marketing campaigns they’ve invested for decades. Influencer marketing is becoming rapidly one of the future marketing tool for companies to reach the customers and for brand awareness. 
Some businesses go straight for the high-profile influencers with millions of followers, like D’Amelio or athletes and celebrities–whom Godbould call “mega” influencers. But these partnerships cost a great deal.
</div>

                </div>
                <div className="service-image">
                    <div className="background">
                        <img className='slide-img' src={social} alt="" />

                    </div>

                </div>



            </div>
                <div className='faq'>
                    <div className="inner-faq">
                        <div className="faq-head" ref={faqHeadRef}>
                            <h2 className={`faq-h2 ${faqHeadAnimated ? 'animated' : ''}`}>FAQ’S</h2>
                            <h3 className={`faq-h4 ${faqHeadAnimated ? 'animated' : ''}`}>Frequently asked questions</h3>
                            <p className={`faq-h5 ${faqHeadAnimated ? 'animated' : ''}`}>Can’t find the answer you’re looking for? Fill the Contact Form Below, Our Team will reach out to you soon.</p>
                        </div>
                        <div className="accordian">
                            <Accordian data={accordionItems} />
                        </div>
                    </div>
                </div>
                <Contactus where={"Influencer Marketing"}/>
        </div>
        <Footer/>
                </>
                )}
                </>
    )
}

export default Influencer

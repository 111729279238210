import {React, useState, useEffect} from 'react'
import Contactus from './Contact us/Contactus'
import Faq from './FAQ/Faq'
import Navbar from './Navbar/Navbar'
import Footer from './footer/Footer'
import Loaders from './Loader'

const ContactusPage = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay for demonstration purposes
    setTimeout(() => {
      setIsLoading(false); // Set isLoading to false when everything is loaded
    }, 3000);
  }, []);
  return (
      <>
      {isLoading ? (
          <Loaders />
        ) : (
          <>
    <Navbar/>
      <Contactus where={"Homepage"} />
      <Faq/>
      <Footer/>
                </>
                )}
                </>
  )
}

export default ContactusPage

import React, { useState, useEffect } from 'react';
import send from "../media/send.svg";
import x from "../media/x.svg";
import ThankYoupopup from './ThankYoupopup';

const Submittedpopup = ({ onClose, where }) => {
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [showThankYouPopup, setShowThankYouPopup] = useState(false);

 

  const handleClosePopup = () => {
    onClose();
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    let url;

    if (where.includes('Brands')) {
      url = 'https://api.creator.ind.in/api/submit-callback-request/';
    } else {
      url = 'https://api.creator.ind.in/api/creators-callback-request/';
    }

    

    try {

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          number,
          where
        }),
      });

      if (response.status === 201) {
        setShowThankYouPopup(true);
      } else {
      }
    } catch (error) {
    }
  };

  return (
    <div className="popup popup1 open">
      <div className="popup-content content1">
        <div onClick={handleClosePopup} className="cross">
          <img src={x} alt="" />
        </div>
        <h2>LETS CONNECT ON CALL</h2>
        <form onSubmit={handleFormSubmit}>
          <div className="custom-input input2">
            <input
              type="text"
              name="name"
              placeholder="Enter Your Full Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="custom-input input2">
            <input
              type="number"
              name="number"
              placeholder="Enter Contact No"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              maxLength={10}
            />
          </div>
          <div
            onClick={handleFormSubmit}
            variant="dark"
            type="submit"
            className="submit-btn send-btn"
          >
            Send <img src={send} alt="" className="send" />
          </div>
        </form>
      </div>
      {showThankYouPopup && <ThankYoupopup onClose={handleClosePopup} />}
    </div>
  );
};

export default Submittedpopup;

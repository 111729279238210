import React, { useState, useEffect, useRef } from 'react';
import Accordian from '../components/Accordian/Accordian';

const CFaq = ({style}) => {
  const accordionItems = [
    {
        question: 'How can B Rich help creators secure brand deals?',
        answer: ` B Rich acts as your bridge to brands. We leverage our expertise and network to connect you with brands that align with your style and values, ensuring you secure meaningful collaborations.`,
    },
    {
        question: ' What sets B Rich apart in the realm of strategic partnerships?',
        answer: ` Our strategic partnerships are more than collaborations – they're connections that resonate. We curate partnerships that amplify your influence and expand your reach within your niche and beyond.`,
    },
    {
        question: ` Can B Rich assist with monetizing my creative efforts?`,
        answer: `Absolutely. Our monetization strategies are tailored to transform your creativity into revenue. From ad optimization to product launches, we empower you to turn your passion into earnings.`,
    },
    {
        question: ' What benefits does influencer marketing offer my brand?',
        answer: `Influencer marketing amplifies your brand's reach through authentic connections.
        We collaborate with influencers aligned with your values, fostering real engagement
        and driving meaningful results.`,
    },
    {
        question: ' How does B Rich amplify my influence?',
        answer: ` With B Rich, your voice gains prominence. We position you as an authority in your niche, ensuring your influence reverberates and attracts opportunities that align with your goals.`,
    },
]; const faqHeadRef = useRef();
    const [faqHeadAnimated, setFaqHeadAnimated] = useState(false);
  
    useEffect(() => {
      const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.4,
      };
  
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setFaqHeadAnimated(true);
          observer.unobserve(faqHeadRef.current);
        }
      }, observerOptions);
  
      if (faqHeadRef.current) {
        observer.observe(faqHeadRef.current);
      }
  
      return () => {
        observer.disconnect();
      };
    }, []);
  
    return (
      <div className='faq section' id='faq' >
        <div className="inner-faq">
          <div className="faq-head" ref={faqHeadRef}>
            <h2 className={`faq-h2 ${faqHeadAnimated ? 'animated' : ''}`}>FAQ’S</h2>
            <h3 className={`faq-h4 ${faqHeadAnimated ? 'animated' : ''}`}>Frequently asked questions</h3>
            <p className={`faq-h5 ${faqHeadAnimated ? 'animated' : ''}`}>Everything you need to know about B Rich.</p>
          </div>
          <div className="accordian">
            <Accordian data={accordionItems} />
          </div>
        </div>
      </div>
    );
  }
  
  export default CFaq;
import './App.css';
import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from './components/Navbar/Navbar';
import Banner from './components/Bannner/Banner';
import Discover from './components/Discover/Discover';
import Process from './components/Process/Process';
import Brands from './components/Brands/Brands';
import Faq from './components/FAQ/Faq';
import Contactus from './components/Contact us/Contactus';
import Footer from './components/footer/Footer';
import Homepage from './components/Homepage/Homepage';
import Social from './components/All services/Social';
import Zigzag from './components/All services/ZigZag';
import Conceptual from './components/All services/Conceptual';
import Digital from './components/All services/Digital';
import Influencer from './components/All services/Influencer';
import Live from './components/All services/Live';
import Paid from './components/All services/Paid';
import Reach from './components/All services/Reach';
import Reputation from './components/All services/Reputation';
import ZigZag from './components/All services/ZigZag';
import CHomepage from './Creators/Creator Homepage/CHomepage';
import ContactusPage from './components/ContactusPage';
import Analytics from './Creators/CServices/Analytics';
import Authentic from './Creators/CServices/Authentic';
import BrandDeal from './Creators/CServices/BrandDeal';
import Influence from './Creators/CServices/Influence';
import Moneytization from './Creators/CServices/Moneytization';
import Reaches from './Creators/CServices/Reputatione';
import Safety from './Creators/CServices/Safety';
import Stratergic from './Creators/CServices/Stratergic';
import Tailored from './Creators/CServices/Tailored';
import Reputatione from './Creators/CServices/Reputatione';
import CContactus from './Creators/CContactus';
import Loader from './components/Loader';
import CContactusPage from './Creators/CContactpage';
import CNavbar from './Creators/Cnavbar';
import CFooter from './Creators/CFooter';

function App() { 
   const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay for demonstration purposes
    setTimeout(() => {
      setIsLoading(false); // Set isLoading to false when everything is loaded
    }, 2000);
  }, []);




  return (
    <BrowserRouter>
    <div className="App">
  
      <Routes>

        <Route
          path="/"
          element={[

      <Homepage/>,


          ]}
        />
        <Route
          path="/social-media-marketing"
          element={[

            <Social />,

          ]}
        />
        <Route
          path="/zig-zag-marketing"
          element={[

            <ZigZag/>,

          ]}
        />
        <Route
          path="/live-management"
          element={[

            <Live />,

          ]}
        />
        <Route
          path="/conceptual-marketing"
          element={[

            <Conceptual />,

          ]}
        />
        <Route
          path="/reach-enhancements"
          element={[

            <Reach />,

          ]}
        />
        <Route
          path="/influencer-marketing"
          element={[

            <Influencer />,

          ]}
        />
        <Route
          path="/reputation-management"
          element={[

            <Reputation />,

          ]}
        />
        <Route
          path="/digital-marketing"
          element={[

            <Digital/>,

          ]}
        />
        <Route
          path="/AI-marketing"
          element={[

            <Paid/>,

          ]}
        />
        <Route
          path="/creators"
          element={[
            <CHomepage/>,
          ]}
        />
        <Route
          path="/contact-us"
          element={[
            <ContactusPage/>,

          ]}
        />
        <Route
          path="/creators/contact-us"
          element={[
            <CContactusPage/>,
          ]}
        />
         <Route path="/creators/unlocking-brands-deal" element={[

          <BrandDeal/>,

        ]} />
          <Route path="/creators/strategic-partnerships" element={[

            <Stratergic/>,

          ]} />
          <Route path="/creators/monetization-strategies" element={[

            <Moneytization/>,

          ]} />
          <Route path="/creators/influence-amplification" element={[

            <Influence/>,

          ]} />
          <Route path="/creators/authentic-engagement" element={[

            <Authentic/>,

          ]} />
          <Route path="/creators/reputation-enhancements" element={[

            <Reputatione/>,

          ]} />
          <Route path="/creators/tailored-guidance" element={[
            <Tailored/>,
          ]} />
          <Route path="/creators/analytics-and-insights" element={[
            <Analytics/>,
          ]} />
          <Route path="/creators/safety-and-protection" element={[
            <Safety/>,
          ]} />
       
      </Routes>
    </div>
  </BrowserRouter>
  );
}

export default App;

import {React, useState, useEffect} from 'react'
import CContactus from './CContactus'
import Faq from '../components/FAQ/Faq'
import CNavbar from './Cnavbar'
import CFooter from './CFooter'
import Loaders from '../components/Loader'

const CContactusPage = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay for demonstration purposes
    setTimeout(() => {
      setIsLoading(false); // Set isLoading to false when everything is loaded
    }, 3000);
  }, []);
  return (
      <>
      {isLoading ? (
          <Loaders />
        ) : (
          <>
    <CNavbar/>
      <CContactus where={'Creators page'}/>
      <Faq/>
      <CFooter/>
                </>
                )}
                </>
  )
}

export default CContactusPage

import { React, useState, useEffect } from 'react';
import brich from "../media/brich.svg";
import logo1 from "../media/c1.svg"
import logo2 from "../media/c2.svg"
import logo3 from "../media/c3.svg"
import logo4 from "../media/c4.svg"
import logo5 from "../media/c5.svg"
import logo6 from "../media/c6.svg"
import logo7 from "../media/c7.svg"
import logo8 from "../media/c8.svg"
import logo9 from "../media/c9.svg"
import menu from "../media/menu.svg"
import line9 from "../media/9line.svg"
import { Link } from 'react-router-dom';


const CNavbar = () => {
    const [expandedDiv, setExpandedDiv] = useState(null);
    const [showServiceMenu, setShowServiceMenu] = useState(false);
    const [isRightNavbarOpen, setIsRightNavbarOpen] = useState(false);
    const [isServiceItemVisible, setIsServiceItemVisible] = useState(false);
    const [isServiceItemClicked, setIsServiceItemClicked] = useState(false);

    const handleServiceItemClick = () => {
        setIsServiceItemVisible(!isServiceItemVisible);
        setIsServiceItemClicked(!isServiceItemClicked);
    };
    useEffect(() => {
        const html = document.getElementsByTagName('body')[0]
      
        if (isRightNavbarOpen) {
          html.classList.add('lock-scroll')
        } else {
          html.classList.remove('lock-scroll')
        }
        return () => {
          html.classList.remove('lock-scroll')
        }
      }, [isRightNavbarOpen])


    const toggleRightNavbar = () => {
        setIsRightNavbarOpen(!isRightNavbarOpen);
    };



    const handleHover = (event, divId) => {
        const hoverEffect = event.target;
        const dataText = hoverEffect.getAttribute('data-text');
        const spanElement = hoverEffect.querySelector('span');

        if (spanElement) {
            spanElement.textContent = dataText;
            hoverEffect.classList.add('expanded');
        }

        if (divId === 'services') {
            setShowServiceMenu(true);
        } else {
            setShowServiceMenu(false);
        }
    };

    const handleMouseLeave = (event, divId) => {
        const hoverEffect = event.target;
        const originalText = hoverEffect.getAttribute('data-original-text');
        const spanElement = hoverEffect.querySelector('span');


        if (spanElement) {
            // setTimeout(() => {
                setExpandedDiv(null);
                spanElement.textContent = originalText;
                hoverEffect.classList.remove('expanded');
            // }, 3000);
        }

    };
    const handleServiceMenuHover = () => {
        setShowServiceMenu(true);
        // setExpandedDiv('services');
    };

    const handleServiceMenuLeft = () => {
        setShowServiceMenu(false);
        // setExpandedDiv(null); 
    }
    const handleServiceClick = () => {
        setShowServiceMenu(!showServiceMenu)
    }
    const handleNavigation = (url) => {
        window.location.href = url; // Redirect to the specified URL
    };

    const handleHoverDiv = (url) => {
    
        // Open the URL in a new tab
        window.open(url, '_blank');
      };

    return (
        <div className='nav-wrapper' id='navbar'>
            <div className='Navbar'>
                <Link to={"/creators"} className="logos">
                    <div ><img className="logo" src={brich} alt="" /></div>
                    <div className="name">B Rich</div>
                </Link>
                <div className="nav-items">
                    <Link
                        to={'/'}
                        className={`hover-effect ${expandedDiv === 'contact' ? 'expanded' : ''}`}
                        data-text="I’m a Brand 🙋🏻"
                        data-original-text="I’m a Brand"
                        onMouseEnter={(event) => handleHover(event, 'contact')}
                        onMouseLeave={handleMouseLeave}
                    >
                        <span> I’m a Brand</span>
                    </Link>
                    <Link
                        to={'/creators/contact-us'}
                        className={`hover-effect ${expandedDiv === 'services' ? 'expanded' : ''}`}
                        data-text="Contact Us 🫱🏽‍🫲🏼"
                        data-original-text="Contact Us"
                        onMouseEnter={(event) => handleHover(event, 'contact')}
                        onMouseLeave={handleMouseLeave}
                    >
                        <span>Contact Us</span>
                    </Link>
                    <div
                        className={`hover-effect ${expandedDiv === 'services' ? 'expanded' : ''}`}
                        data-text="Services 💎"
                        data-original-text="Services"
                        onMouseEnter={(event) => handleHover(event, 'services')}
                        onMouseLeave={handleMouseLeave}
                    >
                        <span>Services</span>
                    </div>
                    <div
                        onClick={() => handleHoverDiv('https://creators.brich.in/')}
                        className={`hover-effect ${expandedDiv === 'services' ? 'expanded' : ''}`}
                        data-text="Creator Dashboard 📊"
                        data-original-text="Creator Dashboard"
                        onMouseEnter={(event) => handleHover(event, 'contact')}
                        onMouseLeave={handleMouseLeave}
                        style={{ maxWidth: "169px", width: "100%" }}                    

                    >
                        <span>Creator Dashboard</span>
                    </div>
                </div>
                <div className="tab-menu">
                    <Link to={"/"} className="expanded1">I’m a Brand 🙋🏻</Link>
                    <Link to={'/creators/contact-us'} className="expanded1">Contact Us 🫱🏽‍🫲🏼</Link>
                    <div className="expanded1" onClick={handleServiceClick}>Services 💎</div>
                    <div onClick={() => handleHoverDiv('https://creators.brich.in/')}  style={{maxWidth:"169px"}} className="expanded1">Creator Dashboard 📊</div>
                </div>
                {showServiceMenu && (
                    <div
                        className={`service-menu ${expandedDiv === 'services' ? '' : ''}`}
                        // onMouseEnter={(event) => handleHover(event, 'services')}
                        // onMouseLeave={handleMouseLeave}
                        onMouseEnter={handleServiceMenuHover}
                        onMouseLeave={handleServiceMenuLeft}
                    >


<div className="servive-item" onClick={() => handleNavigation('/creators/unlocking-brands-deal')}>
  <div> <img src={logo1} alt="" /> </div> Unlocking Brand Deals
</div>
<div className="servive-item" onClick={() => handleNavigation('/creators/strategic-partnerships')}>
  <div> <img src={logo2} alt="" /> </div> Strategic Partnerships
</div>
<div className="servive-item" onClick={() => handleNavigation('/creators/monetization-strategies')}>
  <div> <img src={logo3} alt="" /> </div> Monetization Strategies
</div>
<div className="servive-item" onClick={() => handleNavigation('/creators/influence-amplification')}>
  <div> <img src={logo4} alt="" /> </div> Influence Amplification
</div>
<div className="servive-item" onClick={() => handleNavigation('/creators/authentic-engagement')}>
  <div> <img src={logo5} alt="" /> </div> Authentic Engagement
</div>
<div className="servive-item" onClick={() => handleNavigation('/creators/reputation-enhancements')}>
  <div> <img src={logo6} alt="" /> </div> Reputation Enhancement
</div>
<div className="servive-item" onClick={() => handleNavigation('/creators/tailored-guidance')}>
  <div> <img src={logo7} alt="" /> </div> Tailored Guidance
</div>
<div className="servive-item" onClick={() => handleNavigation('/creators/analytics-and-insights')}>
  <div> <img src={logo8} alt="" /> </div> Analytics and Insights
</div>
<div className="servive-item" onClick={() => handleNavigation('/creators/safety-and-protection')}>
  <div> <img src={logo9} alt="" /> </div> Safety & Protection
</div>

                    </div>
                )}
                <div className={`sliding-logo ${isRightNavbarOpen ? 'open' : ''}`} onClick={toggleRightNavbar}>
                    <img src={menu} alt="" />
                </div>
                <div className={`right-navbar ${isRightNavbarOpen ? 'open' : ''}`}>
                    <div className='right1'>
                        <img src={line9} alt="Menu" onClick={toggleRightNavbar} />
                    </div>
                    <div className="nav-item">
                        <div onClick={() => handleNavigation('/')}>🙋🏻  I’m a Brand </div>
                        <div onClick={() => handleNavigation('/creators/contact-us')}>🫱🏽‍🫲🏼  Contact Us </div>
                        <div onClick={() => handleHoverDiv('https://creators.brich.in/')}>📊 Creator Dashboard</div>
                        <div
                            className={`service-item ${isServiceItemVisible ? 'visible' : ''}`}
                            onClick={handleServiceItemClick}
                        >💎 Services </div>
                        {/* Add more list items as needed */}
                    </div>
                    {isServiceItemClicked && (
                        <div
                            className={`service-item`}
                        >
                            <div onClick={() => handleNavigation('/creators/unlocking-brands-deal')}>
                                <div>
                                    <img src={logo1} alt="" />
                                </div>
                                Unlocking Brand Deals
                            </div>
                            <div onClick={() => handleNavigation('/creators/strategic-partnerships')}>
                                <div>
                                    <img src={logo2} alt="" />
                                </div>
                                Strategic Partnerships
                            </div>
                            <div onClick={() => handleNavigation('/creators/monetization-strategies')}>
                                <img src={logo3} alt="" />
                                Monetization Strategies
                            </div>
                            <div onClick={() => handleNavigation('/creators/influence-amplification')}>
                                <img src={logo4} alt="" />
                                Influence Amplification
                            </div>
                            <div onClick={() => handleNavigation('/creators/authentic-engagement')}>
                                <img src={logo5} alt="" />
                                Authentic Engagement
                            </div>
                            <div onClick={() => handleNavigation('/creators/reputation-enhancements')}>
                                <img src={logo6} alt="" />
                                Reputation Enhancement
                            </div>
                            <div onClick={() => handleNavigation('/creators/tailored-guidance')}>
                                <img src={logo7} alt="" />
                                Tailored Guidance
                            </div>
                            <div onClick={() => handleNavigation('/creators/analytics-and-insights')}>
                                <img src={logo8} alt="" />
                                Analytics and Insights
                            </div>
                            <div onClick={() => handleNavigation('/creators/safety-and-protection')}>
                                <img src={logo9} alt="" />
                                Safety & Protection
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>

    );
};

export default CNavbar;


    //   const handleHover = (event) => {
    //     const hoverEffect = event.target;
    //     const dataText = hoverEffect.getAttribute('data-text');
    //     hoverEffect.querySelector('span').textContent = dataText;
    //   };

    //   const handleMouseLeave = (event) => {
    //     const hoverEffect = event.target;
    //     const originalText = hoverEffect.getAttribute('data-original-text');
    //     hoverEffect.querySelector('span').textContent = originalText;
    //   };
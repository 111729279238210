import { React, useRef, useState, useEffect } from 'react'
import "./Allservice.css"
import Accordian from '../Accordian/Accordian';
import Contactus from '../Contact us/Contactus';
import social from "../../media/reach.svg"
import Footer from '../footer/Footer';
import Navbar from '../Navbar/Navbar';
import Loaders from '../Loader';

const Reach = () => {
    const accordionItems = [
        {
            question: 'What exactly are Reach Enhancements, and how can they benefit my brand?',
            answer: `Reach Enhancements are a suite of strategies and technologies designed to expand your brand's reach to new territories and connect with previously untapped audiences. They benefit your brand by increasing visibility and engagement, ultimately helping you connect with a broader and more diverse audience, leading to growth and opportunities.`,
        },
        {
            question: 'How do Reach Enhancements differ from traditional marketing and outreach strategies?',
            answer: `Reach Enhancements focus on leveraging advanced techniques and tailored solutions to broaden your brand's reach. They go beyond traditional methods, incorporating data-driven approaches and technology to open doors to new territories and demographics that may have been overlooked.`,
        },
        {
            question: `Can Reach Enhancements be customized to fit my brand's unique goals and target audience?`,
            answer: `Absolutely. Reach Enhancements are highly customizable. We work closely with you to understand your brand's objectives, target audience, and specific needs. From there, we tailor a strategy that aligns with your brand's unique goals, ensuring the enhancements are a perfect fit.`,
        },
        {
            question: 'Which industries or businesses can benefit from Reach Enhancements?',
            answer: `Reach Enhancements can benefit a wide range of industries and businesses. They are especially valuable for brands looking to expand into new markets, reach a more diverse audience, or enhance their current reach strategies.`,
        },
        {
            question: 'How can I get started with Reach Enhancements to take my brand to new horizons?',
            answer: `To get started with Reach Enhancements, simply contact us through our website or by reaching out to our team. We'll schedule a consultation to discuss your brand's objectives and audience, and from there, we'll develop a customized strategy to expand your brand's reach and connect with untapped audiences.`,
        },
    ];
     const faqHeadRef = useRef();
    const [faqHeadAnimated, setFaqHeadAnimated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setFaqHeadAnimated(true);
                observer.unobserve(faqHeadRef.current);
            }
        }, observerOptions);

        if (faqHeadRef.current) {
            observer.observe(faqHeadRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [isLoading]);

    useEffect(() => {
      // Simulate a loading delay for demonstration purposes
      setTimeout(() => {
        setIsLoading(false); // Set isLoading to false when everything is loaded
      }, 3000);
    }, []);
    return (
        <>
        {isLoading ? (
            <Loaders />
          ) : (
            <>
            <Navbar/>        <div div className='outer'>
            <div className='general'>
                <div className="info">
                    <div className="info-head">Reach Enhancements</div>
                    <div className="info-d">“Expand your brand's reach beyond boundaries. 
Our tailored solutions open doors to new territories, connecting you 
with untapped audiences.”</div>
                    <div className="info-p">Brich will create innovative strategies for companies to reach unknown territories which were not explored by the companies through existing marketing strategies. The reach of the company will auto-generate the revenue and increase its brand values. In Longterm marketing, the Reach management will create the unique edge for companies to reach the new place. <br />
</div>

                </div>
                <div className="service-image">
                    <div className="background">
                        <img className='slide-img' src={social} alt="" />

                    </div>

                </div>



            </div>
                <div className='faq'>
                    <div className="inner-faq">
                        <div className="faq-head" ref={faqHeadRef}>
                            <h2 className={`faq-h2 ${faqHeadAnimated ? 'animated' : ''}`}>FAQ’S</h2>
                            <h3 className={`faq-h4 ${faqHeadAnimated ? 'animated' : ''}`}>Frequently asked questions</h3>
                            <p className={`faq-h5 ${faqHeadAnimated ? 'animated' : ''}`}>Can’t find the answer you’re looking for? Fill the Contact Form Below, Our Team will reach out to you soon.</p>
                        </div>
                        <div className="accordian">
                            <Accordian data={accordionItems} />
                        </div>
                    </div>
                </div>
                <Contactus where={"Reach Enhancements"}/>
        </div>
        <Footer/>
                </>
                )}
                </>
    )
}

export default Reach

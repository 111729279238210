import React, { useEffect, useRef, useState } from 'react';
import "./Brand.css";
import brand1 from "../../media/brandlogo/web.png";
import brand2 from "../../media/brandlogo/soul.png";
import brand3 from "../../media/brandlogo/buzz.svg";
import brand35 from "../../media/brandlogo/youth.png";
import brand4 from "../../media/brandlogo/home.svg";
import brand5 from "../../media/brandlogo/braj.png";
import brand6 from "../../media/brandlogo/say.png";
import brand7 from "../../media/brandlogo/taneja.svg";
import brand8 from "../../media/brandlogo/fruits.png";
import brand9 from "../../media/brandlogo/lassi.png";
import brand10 from "../../media/brandlogo/give.png";
import brand11 from "../../media/brandlogo/eq.svg";
import brand12 from "../../media/brandlogo/neelam.svg";

const Brands = ({style}) => {
  const brandsRef = useRef();
  const [brandsAnimated, setBrandsAnimated] = useState(false);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setBrandsAnimated(true);
        observer.unobserve(brandsRef.current);
      }
    }, observerOptions);

    if (brandsRef.current) {
      observer.observe(brandsRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className='Brands section' ref={brandsRef} id='brands' style={style}>
      <h2 className={`brandh2 ${brandsAnimated ? 'animated' : ''}`}>Our Brand Collaborations</h2>
      <div className={`all-brands ${brandsAnimated ? 'animated' : ''}`}>
        <img src={brand1} alt="" />
        <img src={brand2} alt="" />
        <img src={brand3} alt="" />
        <img src={brand35} alt="" />
        <img src={brand4} alt="" />
        <img src={brand5} alt="" />
        <img src={brand6} alt="" />
        <img src={brand7} alt="" />
        <img src={brand8} alt="" />
        <img src={brand9} alt="" />
        <img src={brand10} alt="" />
        <img src={brand11} alt="" />
        <img src={brand12} alt="" />
      </div>
    </div>
  );
}

export default Brands;

import { React, useRef, useState, useEffect } from 'react'
import "./Allservice.css"
import Accordian from '../Accordian/Accordian';
import Contactus from '../Contact us/Contactus';
import social from "../../media/live.svg"
import Footer from '../footer/Footer';
import Navbar from '../Navbar/Navbar';
import Loaders from '../Loader';

const Live = () => {
    const accordionItems = [
        {
            question: 'What is Live Management, and how can it benefit my brand or business?',
            answer: `Live Management is all about creating and maintaining authentic, real-time connections with your audience. It benefits your brand by fostering meaningful interactions that resonate deeply with your audience, making your live events, webinars, or social media engagements more engaging and memorable.`,
        },
        {
            question: 'How can Live Management help me stand out in the digital landscape where real-time interactions are vital?',
            answer: `Live Management is essential in today's digital landscape, where real-time connections are highly valued. Our strategies ensure that your live interactions are not only real but also authentic, helping your brand stand out by creating genuine connections that leave a lasting impression.`,
        },
        {
            question: 'What types of events or engagements can benefit from Live Management services?',
            answer: `Live Management can benefit a wide range of events, including live webinars, social media live streams, virtual events, product launches, Q&A sessions, and more. Any situation where real-time engagement with your audience is crucial can benefit from our services.`,
        },
        {
            question: 'How do you ensure authenticity and engagement during live events or interactions?',
            answer: `We employ a combination of interactive content, audience engagement techniques, and expert hosts or presenters to create an environment that encourages participation and authenticity. We focus on tailoring our approach to the specific needs and goals of your event.`,
        },
        {
            question: 'How can I get started with Live Management services for my brand\'s live events or engagements?',
            answer: `To get started with Live Management, simply reach out to us through our contact page. We'll schedule a consultation to discuss your specific event or engagement requirements, goals, and audience, and then work with you to develop a customized strategy that fosters genuine connections and keeps your audience engaged in real-time.`,
        },
    ];
     const faqHeadRef = useRef();
    const [faqHeadAnimated, setFaqHeadAnimated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setFaqHeadAnimated(true);
                observer.unobserve(faqHeadRef.current);
            }
        }, observerOptions);

        if (faqHeadRef.current) {
            observer.observe(faqHeadRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [isLoading]);

    useEffect(() => {
      // Simulate a loading delay for demonstration purposes
      setTimeout(() => {
        setIsLoading(false); // Set isLoading to false when everything is loaded
      }, 3000);
    }, []);
    return (
        <>
        {isLoading ? (
            <Loaders />
          ) : (
            <>
            <Navbar/>        <div div className='outer'>
            <div className='general'>
                <div className="info">
                    <div className="info-head">Live Management</div>
                    <div className="info-d">“Forge genuine connections in real-time. Our live management 
strategies create authentic interactions that resonate and keep your audience engaged.

”</div>
                    <div className="info-p">Live management will create live campaigns and strategies to the companies an influencer to reach their audience. <br />
B Rich will provide all tools and technologies to influencers to build themselves and promote the compani’s products and services to generate the revenue. <br />
Live management will be of a unique concept which will bing revolutionised changes in marketing.
</div>

                </div>
                <div className="service-image">
                    <div className="background">
                        <img className='slide-img' src={social} alt="" />

                    </div>

                </div>



            </div>
                <div className='faq'>
                    <div className="inner-faq">
                        <div className="faq-head" ref={faqHeadRef}>
                            <h2 className={`faq-h2 ${faqHeadAnimated ? 'animated' : ''}`}>FAQ’S</h2>
                            <h3 className={`faq-h4 ${faqHeadAnimated ? 'animated' : ''}`}>Frequently asked questions</h3>
                            <p className={`faq-h5 ${faqHeadAnimated ? 'animated' : ''}`}>Can’t find the answer you’re looking for? Fill the Contact Form Below, Our Team will reach out to you soon.</p>
                        </div>
                        <div className="accordian">
                            <Accordian data={accordionItems} />
                        </div>
                    </div>
                </div>
                <Contactus where={"Live Management"}/>
        </div>
        <Footer/>
                </>
                )}
                </>
    )
}

export default Live

import { React, useRef, useState, useEffect } from 'react'
import "./Allservice.css"
import Accordian from '../Accordian/Accordian';
import Contactus from '../Contact us/Contactus';
import social from "../../media/repu.svg"
import Footer from '../footer/Footer';
import Navbar from '../Navbar/Navbar';
import Loaders from '../Loader';

const Reputation = () => {
    const accordionItems = [
        {
            question: 'What is Reputation Management, and how can it benefit my brand or business?',
            answer: `Reputation Management involves the proactive shaping of your brand's digital identity to build trust and credibility. It benefits your brand by ensuring that your online presence aligns with your values and resonates positively with your audience, which can enhance brand integrity and attract more customers.`,
        },
        {
            question: 'How does Reputation Management differ from traditional online marketing or branding efforts?',
            answer: `Reputation Management is distinct from traditional marketing efforts because it focuses on actively curating and maintaining your brand's online image. It involves a comprehensive approach that goes beyond reactive measures, working to shape and protect your brand's reputation over time.`,
        },
        {
            question: 'What types of businesses or industries can benefit from Reputation Management services?',
            answer: `Reputation Management is valuable for a wide range of businesses and industries. It's especially beneficial for those concerned with maintaining a positive online presence, protecting against negative online reviews, and proactively building trust among their audience.`,
        },
        {
            question: 'How do you proactively build trust and credibility in Reputation Management?',
            answer: `We employ a combination of strategies, including online monitoring, content curation, review management, and proactive engagement with your audience. By ensuring your online presence reflects your values and addresses concerns promptly, we proactively build trust and credibility.`,
        },
        {
            question: 'What can a brand expect after implementing Reputation Management services?',
            answer: `Brands can expect an improved online reputation, increased trust and credibility, and a more positive online presence. Reputation Management can also help protect your brand from reputational crises by addressing issues promptly and ensuring your brand's integrity is upheld.`,
        },
    ];
     const faqHeadRef = useRef();
    const [faqHeadAnimated, setFaqHeadAnimated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setFaqHeadAnimated(true);
                observer.unobserve(faqHeadRef.current);
            }
        }, observerOptions);

        if (faqHeadRef.current) {
            observer.observe(faqHeadRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [isLoading]);

    useEffect(() => {
      // Simulate a loading delay for demonstration purposes
      setTimeout(() => {
        setIsLoading(false); // Set isLoading to false when everything is loaded
      }, 3000);
    }, []);
    return (
        <>
        {isLoading ? (
            <Loaders />
          ) : (
            <>
            <Navbar/>        <div div className='outer'>
            <div className='general'>
                <div className="info">
                    <div className="info-head">Reputation Management</div>
                    <div className="info-d">“Shape your brand's digital identity with our comprehensive
reputation management. We proactively enhance your online presence, 
building trust and credibility.
.
”</div>
                    <div className="info-p">B Rich will create unique strategies to manage the reputation of the Individuals and also companies. New technologies and social networking websites and app are damaging the company’s reputations and also individual reputations with fake and false messages. We Brich will work on building the reputation and bring the positive atmosphere for the companies and individual. Brich will help individuals and companies to build their our reputation through various tools and technologies.</div>

                </div>
                <div className="service-image">
                    <div className="background">
                        <img className='slide-img' src={social} alt="" />

                    </div>

                </div>



            </div>
                <div className='faq'>
                    <div className="inner-faq">
                        <div className="faq-head" ref={faqHeadRef}>
                            <h2 className={`faq-h2 ${faqHeadAnimated ? 'animated' : ''}`}>FAQ’S</h2>
                            <h3 className={`faq-h4 ${faqHeadAnimated ? 'animated' : ''}`}>Frequently asked questions</h3>
                            <p className={`faq-h5 ${faqHeadAnimated ? 'animated' : ''}`}>Can’t find the answer you’re looking for? Fill the Contact Form Below, Our Team will reach out to you soon.</p>
                        </div>
                        <div className="accordian">
                            <Accordian data={accordionItems} />
                        </div>
                    </div>
                </div>
                <Contactus where={"Reputation Management"}/>
        </div>
        <Footer/>
                </>
                )}
                </>
    )
}

export default Reputation

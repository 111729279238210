import React, { useEffect, useRef, useState } from 'react';
import logo1 from "../media/ca.svg"
import logo2 from "../media/cb.svg"
import logo3 from "../media/cc.svg"
import logo4 from "../media/cd.svg"
import logo5 from "../media/ce.svg"
import logo6 from "../media/cf.svg"
import logo7 from "../media/cg.svg"
import logo8 from "../media/ch.svg"
import logo9 from "../media/ci.svg"
import left from "../media/leftcard.svg"
import right from "../media/rightcard.svg"
import { Link } from 'react-router-dom';

const CDiscover = ({style}) => {
   const componentRef = useRef();
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const options ={
            root: null,
            rootMargin: '0px',
            threshold: 0.5, // Change this value as needed
        };
      

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    // Disconnect the observer after the animation has played
                    observer.disconnect();
                }
            });
        }, options);

        if (componentRef.current) {
            observer.observe(componentRef.current);
        }

        return () => {
            if (componentRef.current) {
                observer.unobserve(componentRef.current);
            }
        };
      },[]);
      const scrollToTop = () => {
        window.scrollTo(0, 0)
    }
    
      
    const discoverRef = useRef(null);

  return (
    <div className='discover section' id='discover' ref={discoverRef} style={style}>
        <div className="frameimg">
          <img className='imgleft' src={left} alt="" />
          <img className='imgright' src={right} alt="" />
        </div>
      <div className="inner-frame">

        <h2 ref={componentRef} className={`discover-head ${isVisible ? 'fade-in' : ''}`}>Discover Our Service Spectrum</h2>
        <div className="service">
        <Link to="/creators/unlocking-brands-deal" className="box1" onClick={scrollToTop}>
  <img src={logo1} alt="" /> Unlocking Brand Deals
</Link>
<Link to="/creators/strategic-partnerships" className="box2" onClick={scrollToTop}>
  <img src={logo2} alt="" /> Strategic Partnerships
</Link>
<Link to="/creators/monetization-strategies" className="box3" onClick={scrollToTop}>
  <img src={logo3} alt="" /> Monetization Strategies
</Link>
<Link to="/creators/influence-amplification" className="box4" onClick={scrollToTop}>
  <img src={logo4} alt="" /> Influence Amplification
</Link>
<Link to="/creators/authentic-engagement" className="box5" onClick={scrollToTop}>
  <img src={logo5} alt="" /> Authentic Engagement
</Link>
<Link to="/creators/reputation-enhancements" className="box6" onClick={scrollToTop}>
  <img src={logo6} alt="" /> Reputation Enhancement
</Link>
<Link to="/creators/tailored-guidance" className="box7" onClick={scrollToTop}>
  <img src={logo7} alt="" /> Tailored Guidance
</Link>
<Link to="/creators/analytics-and-insights" className="box8" onClick={scrollToTop}>
  <img src={logo8} alt="" /> Analytics and Insights
</Link>
<Link to="/creators/safety-and-protection" className="box9" onClick={scrollToTop}>
  <img src={logo9} alt="" /> Safety & Protection
</Link>
        </div>
      </div>

    </div>
  )
}

export default CDiscover

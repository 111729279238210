import React, { useEffect, useRef, useState } from 'react';
import "./discover.css"
import logo1 from "../../media/a.svg"
import logo2 from "../../media/b.svg"
import logo3 from "../../media/c.svg"
import logo4 from "../../media/d.svg"
import logo5 from "../../media/e.svg"
import logo6 from "../../media/f.svg"
import logo7 from "../../media/g.svg"
import logo8 from "../../media/h.svg"
import logo9 from "../../media/i.svg"
import left from "../../media/leftcard.svg"
import right from "../../media/rightcard.svg"
import { Link } from 'react-router-dom';

const Discover = ({style}) => {
   const componentRef = useRef();
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5, // Change this value as needed
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    // Disconnect the observer after the animation has played
                    observer.disconnect();
                }
            });
        }, options);

        if (componentRef.current) {
            observer.observe(componentRef.current);
        }

        return () => {
            if (componentRef.current) {
                observer.unobserve(componentRef.current);
            }
        };
    }, []);
    const discoverRef = useRef(null);

    const scrollToTop = () => {
      window.scrollTo(0, 0)
  }
  

  return (
    <div className='discover section' id='discover' ref={discoverRef} style={style}>
        <div className="frameimg">
          <img className='imgleft' src={left} alt="" />
          <img className='imgright' src={right} alt="" />
        </div>
      <div className="inner-frame">

        <h2 ref={componentRef} className={`discover-head ${isVisible ? 'fade-in' : ''}`}>Discover Our Service Spectrum</h2>
        <div className="service">
        <Link to="/social-media-marketing" className="box1" onClick={scrollToTop}>
  <img src={logo1} alt="" /> Social Media Marketing
</Link>
<Link to="/conceptual-marketing" className="box2"  onClick={scrollToTop}>
  <img src={logo2} alt="" /> Conceptual Marketing
</Link>
<Link to="/influencer-marketing" className="box3" onClick={scrollToTop}>
  <img src={logo3} alt="" /> Influencer Marketing
</Link>
<Link to="/zig-zag-marketing" className="box4" onClick={scrollToTop}>
  <img src={logo4} alt="" /> Zig Zag Marketing
</Link>
<Link to="/AI-marketing" className="box5" onClick={scrollToTop}>
  <img src={logo5} alt="" /> AI Marketing 
</Link>
<Link to="/reputation-management" className="box6" onClick={scrollToTop}>
  <img src={logo6} alt="" /> Reputation Management
</Link>
<Link to="/live-management" className="box7" onClick={scrollToTop}>
  <img src={logo7} alt="" /> Live Management
</Link>
<Link to="/reach-enhancements" className="box8" onClick={scrollToTop}>
  <img src={logo8} alt="" /> Reach Enhancements
</Link>
<Link to="/digital-marketing" className="box9" onClick={scrollToTop}>
  <img src={logo9} alt="" /> Digital Marketing
</Link>
        </div>
      </div>

    </div>
  )
}

export default Discover

import { React, useRef, useState, useEffect } from 'react'
import "./Allservice.css"
import Accordian from '../Accordian/Accordian';
import Contactus from '../Contact us/Contactus';
import social from "../../media/concep.svg"
import Footer from '../footer/Footer';
import Navbar from '../Navbar/Navbar';
import Loaders from '../Loader';

const Conceptual = () => {
    const accordionItems = [
        {
            question: 'What is Conceptual Marketing, and how does it differ from traditional marketing approaches?',
            answer: `Conceptual Marketing goes beyond traditional marketing by focusing on visionary storytelling. It crafts narratives that transcend the ordinary, setting your brand apart in a crowded marketplace. Our approach is about creating experiences that leave a lasting impression and forge emotional connections with your audience.`,
        },
        {
            question: 'How can Conceptual Marketing help my brand\'s identity and reputation?',
            answer: `Conceptual Marketing is designed to elevate your brand's identity by telling visionary stories that captivate hearts and minds. It sets your brand apart as a beacon of innovation, sparking conversations and igniting curiosity. This, in turn, enhances your brand's reputation and creates a stronger emotional connection with your audience.`,
        },
        {
            question: 'What types of businesses or industries can benefit from Conceptual Marketing\'s services?',
            answer: `Conceptual Marketing can benefit a wide range of businesses and industries looking to create a unique and memorable brand identity. It's especially suitable for those seeking to connect with their audience on a deeper, more emotional level.`,
        },
        {
            question: 'How do you craft and implement visionary storytelling in your marketing strategies?',
            answer: `We craft visionary storytelling by delving into the essence of your brand, identifying the unique and compelling aspects that set it apart. Our strategies involve creating narratives that resonate with your target audience's values and aspirations, ultimately leaving a lasting impression.`,
        },
        {
            question: 'How can I get started with Conceptual Marketing to transform my brand into a beacon of innovation?',
            answer: `To get started with Conceptual Marketing, contact us through our website. We'll schedule a consultation to understand your brand's goals and values. From there, we'll work with you to develop a customized strategy that emphasizes visionary storytelling and helps your brand become a leader in your industry.`,
        },
    ];
    const faqHeadRef = useRef();
    const [faqHeadAnimated, setFaqHeadAnimated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setFaqHeadAnimated(true);
                observer.unobserve(faqHeadRef.current);
            }
        }, observerOptions);

        if (faqHeadRef.current) {
            observer.observe(faqHeadRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [isLoading]);

    useEffect(() => {
        // Simulate a loading delay for demonstration purposes
        setTimeout(() => {
            setIsLoading(false); // Set isLoading to false when everything is loaded
        }, 3000);
    }, []);
    return (
        <>
            {isLoading ? (
                <Loaders />
            ) : (
                <>
                    <Navbar />        <div div className='outer'>
                        <div className='general'>
                            <div className="info">
                                <div className="info-head">Conceptual Marketing </div>
                                <div className="info-d">“Conceptual Marketing strategy specializes in attracting, engaging and keeping audiences who are looking for certain unique products and services. It will create a content, videos podcast and other tools to specifically market companies’ or’ products and services to reach the end user. Conceptual marketing helps to establish expertise, promotes brand awareness and keeps the company a head of competitors.
                                    ”</div>
                                <div className="info-p">Concept and content are important ingredients for social engineering without the concept and content companies can’t reach the customers around the world. 
                                    Conceptual marketing will help companies to reach their fan base, potential customers and existing customers. <br />
                                    
                                </div>

                            </div>
                            <div className="service-image">
                                <div className="background">
                                    <img className='slide-img' src={social} alt="" />

                                </div>

                            </div>



                        </div>
                        <div className='faq'>
                            <div className="inner-faq">
                                <div className="faq-head" ref={faqHeadRef}>
                                    <h2 className={`faq-h2 ${faqHeadAnimated ? 'animated' : ''}`}>FAQ’S</h2>
                                    <h3 className={`faq-h4 ${faqHeadAnimated ? 'animated' : ''}`}>Frequently asked questions</h3>
                                    <p className={`faq-h5 ${faqHeadAnimated ? 'animated' : ''}`}>Can’t find the answer you’re looking for? Fill the Contact Form Below, Our Team will reach out to you soon.</p>
                                </div>
                                <div className="accordian">
                                    <Accordian data={accordionItems} />
                                </div>
                            </div>
                        </div>
                        <Contactus where={"Conceptual Marketing "} />
                    </div>
                    <Footer />
                </>
            )}
        </>
    )
}

export default Conceptual

import { React, useRef, useState, useEffect } from 'react'
import Accordian from '../../components/Accordian/Accordian';
import social from "../../media/safety.svg"
import CContactus from '../CContactus';
import CNavbar from '../Cnavbar';
import CFooter from '../CFooter';
import Loaders from '../../components/Loader';

const Safety = () => {
    const accordionItems = [
        {
            question: 'What is "Safety & Protection" for creators, and how can it benefit me in the digital landscape?',
            answer: `"Safety & Protection" for creators involves implementing measures to protect your intellectual property and combat unauthorized content distribution. It benefits you by providing comprehensive safeguards to maintain control over your creations and secure your work in the digital landscape.`,
        },
        {
            question: 'How does "Safety & Protection" empower creators to protect their intellectual property, and what is the role of content watermarking?',
            answer: `"Safety & Protection" empowers creators by enforcing measures that safeguard their intellectual property. Content watermarking is a powerful tool that allows creators to add a visible watermark to their content, discouraging piracy and helping protect their work from unauthorized distribution.`,
        },
        {
            question: 'Who can benefit from "Safety & Protection" services among creators and when are they most valuable?',
            answer: `"Safety & Protection" services can benefit creators across various fields and niches. They are most valuable when creators want to maintain control over their content, protect their intellectual property, and combat the unauthorized distribution of their work.`,
        },
        {
            question: 'What specific safeguards and measures are employed in "Safety & Protection" to protect the intellectual property of creators?',
            answer: `"Safety & Protection" may include strategies such as content watermarking, intellectual property monitoring, and proactive actions to combat piracy and protect creators' work from unauthorized use.`,
        },
        {
            question: 'How can "Safety & Protection" services help creators maintain control over their content and protect their creations in the digital landscape?',
            answer: `"Safety & Protection" empowers creators to protect their intellectual property and maintain control over their content by implementing effective measures such as content watermarking. This helps in discouraging piracy and safeguarding their work from unauthorized distribution.`,
        },
    ];
    
     const faqHeadRef = useRef();
    const [faqHeadAnimated, setFaqHeadAnimated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setFaqHeadAnimated(true);
                observer.unobserve(faqHeadRef.current);
            }
        }, observerOptions);

        if (faqHeadRef.current) {
            observer.observe(faqHeadRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [isLoading]);

    useEffect(() => {
        // Simulate a loading delay for demonstration purposes
        setTimeout(() => {
            setIsLoading(false); // Set isLoading to false when everything is loaded
        }, 3000);
    }, []);
    return (
        <>
            {isLoading ? (
                <Loaders />
            ) : (
                <>
                    <CNavbar /> 
                           <div div className='outer'>
                        <div className='general'>
                            <div className="info">
                                <div className="info-head">Safety & Protection</div>
                                <div className="info-d">“Enforce measures to protect the intellectual property of creators and curb the unauthorized distribution of their content. The feature of content watermarking empowers creators to add a watermark to their content in order to discourage piracy.”</div>
                                <div className="info-p">Explore 'Safety & Protection,' where we safeguard your creative assets. Our comprehensive measures shield creators' intellectual property and combat unauthorized content distribution. With content watermarking, creators gain a powerful tool to discourage piracy and secure their work. Join us in the fight to protect your creations and maintain control over your content in the digital landscape.</div>

                            </div>
                            <div className="service-image">
                                <div className="background">
                                    <img className='slide-img' src={social} alt="" />

                                </div>

                            </div>



                        </div>
                        <div className='faq'>
                            <div className="inner-faq">
                                <div className="faq-head" ref={faqHeadRef}>
                                    <h2 className={`faq-h2 ${faqHeadAnimated ? 'animated' : ''}`}>FAQ’S</h2>
                                    <h3 className={`faq-h4 ${faqHeadAnimated ? 'animated' : ''}`}>Frequently asked questions</h3>
                                    <p className={`faq-h5 ${faqHeadAnimated ? 'animated' : ''}`}>Can’t find the answer you’re looking for? Fill the Contact Form Below, Our Team will reach out to you soon.</p>
                                </div>
                                <div className="accordian">
                                    <Accordian data={accordionItems} />
                                </div>
                            </div>
                        </div>
                        <CContactus where={"Safety & Protection"} />
                    </div>
                    <CFooter />
                </>
            )}
        </>
    )
}

export default Safety

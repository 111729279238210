import { React, useRef, useState, useEffect } from 'react'
import "./Allservice.css"
import Accordian from '../Accordian/Accordian';
import Contactus from '../Contact us/Contactus';
import social from "../../media/Social.svg"
import Loaders from '../Loader';
import Navbar from '../Navbar/Navbar';
import Footer from '../footer/Footer';

const Social = () => {
    const accordionItems = [
        {
            question: 'What is social media marketing, and how can it benefit my brand?',
            answer: `Social media marketing is the practice of using social media platforms to connect with your audience to build your brand, increase sales, and drive website traffic. It benefits your brand by enhancing brand awareness, engaging with your audience, and ultimately increasing conversions and customer loyalty.`,
        },
        {
            question: 'Why should I choose B Rich for my social media marketing needs?',
            answer: `B Rich specializes in crafting strategic social media campaigns that aim to go beyond just generating likes and shares. We focus on creating meaningful experiences and genuine connections with your target audience, helping your brand stand out and thrive in the competitive social media landscape.`,
        },
        {
            question: 'What social media platforms do you work with?',
            answer: `We work with a variety of social media platforms, including but not limited to Facebook, Instagram, Twitter, LinkedIn, and YouTube. We tailor our strategy to fit the platforms that are most relevant to your target audience and industry.`,
        },
        {
            question: 'How do you measure the success of a social media marketing campaign?',
            answer: `Success is measured through various key performance indicators (KPIs) such as engagement rates, follower growth, website traffic, conversion rates, and return on investment (ROI). We use a combination of these metrics to assess the effectiveness of our campaigns and make data-driven adjustments as necessary.`,
        },
        {
            question: 'What kind of content and posts do you create for social media marketing?',
            answer: `We create a diverse range of content, including eye-catching graphics, engaging videos, informative articles, and interactive posts. Our content is designed to align with your brand's identity and resonate with your target audience, ultimately driving engagement and achieving your marketing goals.`,
        },
        {
            question: 'How can I get started with B Rich\'s social media marketing services?',
            answer: `Getting started with us is easy. You can reach out to us through our contact page, and we'll schedule a consultation to discuss your brand's needs, goals, and budget. From there, we'll develop a tailored social media marketing strategy to help your brand succeed on social platforms.`,
        },
    ];
    const faqHeadRef = useRef();
    const [faqHeadAnimated, setFaqHeadAnimated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setFaqHeadAnimated(true);
                observer.unobserve(faqHeadRef.current);
            }
        }, observerOptions);

        if (faqHeadRef.current) {
            observer.observe(faqHeadRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [isLoading]);

    useEffect(() => {
        // Simulate a loading delay for demonstration purposes
        setTimeout(() => {
            setIsLoading(false); // Set isLoading to false when everything is loaded
        }, 3000);
    }, []);
    return (
        <>
            {isLoading ? (
                <Loaders />
            ) : (
                <>
                    <Navbar />
                    <div div className='outer'>
                        <div className='general'>
                            <div className="info">
                                <div className="info-head">SOCIAL MEDIA MARKETING</div>
                                <div className="info-d">“Social media marketing helps companies to create brand awareness, brand availability and product reach
                                    ”</div>
                                <div className="info-p">Social media apps such as Facebook, Instagram Twitter X, pine test YouTube, LinkedIn, snapchat has the elements which makes users use and they influence the behaviour of users in their buying behaviour Most of the companies are promoting their products and services through main stream social network sites and also through web-based apps. 
                              
                                    
                                   
                                </div>

                            </div>
                            <div className="service-image">
                                <div className="background">
                                    <img className='slide-img' src={social} alt="" />

                                </div>

                            </div>



                        </div>
                        <div className='faq'>
                            <div className="inner-faq">
                                <div className="faq-head" ref={faqHeadRef}>
                                    <h2 className={`faq-h2 ${faqHeadAnimated ? 'animated' : ''}`}>FAQ’S</h2>
                                    <h3 className={`faq-h4 ${faqHeadAnimated ? 'animated' : ''}`}>Frequently asked questions</h3>
                                    <p className={`faq-h5 ${faqHeadAnimated ? 'animated' : ''}`}>Can’t find the answer you’re looking for? Fill the Contact Form Below, Our Team will reach out to you soon.</p>
                                </div>
                                <div className="accordian">
                                    <Accordian data={accordionItems} />
                                </div>
                            </div>
                        </div>
                        <Contactus where={"SOCIAL MEDIA MARKETING"} />
                    </div>
                    <Footer />
                </>
            )}

        </>
    )
}

export default Social

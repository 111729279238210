import React, { useState } from 'react';
import { Button } from './Styles';
import { Link } from 'react-scroll';
import top from "../../media/top.svg"


const ScrollButton = () => {

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 500) {
            setVisible(true)
        }
        else if (scrolled <= 500) {
            setVisible(false)
        }
    };


    window.addEventListener('scroll', toggleVisible);

    return (
        <Button>
            <Link to='navbar' smooth={false} duration={500}>
                <img src={top} alt='alt' style={{ display: visible ? 'inline' : 'none' }} />
            </Link>
        </Button>
    );
}

export default ScrollButton; 

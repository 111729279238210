import { React, useRef, useState, useEffect } from 'react'
import "./Allservice.css"
import Accordian from '../Accordian/Accordian';
import Contactus from '../Contact us/Contactus';
import social from "../../media/zigzag.svg"
import Footer from '../footer/Footer';
import Navbar from '../Navbar/Navbar';
import Loaders from '../Loader';

const ZigZag = () => {
    const accordionItems = [
        {
            question: 'What is Zig Zag Marketing, and how does it differ from traditional marketing approaches?',
            answer: `Zig Zag Marketing is all about breaking away from conventional marketing strategies. We employ unique, unconventional approaches to ignite curiosity, capture attention, and drive engagement. Unlike traditional methods, we zig when others zag to create a buzz that's hard to ignore.`,
        },
        {
            question: 'How can Zig Zag Marketing help my business stand out in a crowded marketplace?',
            answer: `Zig Zag Marketing specializes in crafting campaigns that captivate and turn heads. Our unconventional strategies are designed to spark curiosity, making your brand stand out amidst the competition and fueling engagement with your target audience.`,
        },
        {
            question: 'What types of businesses or industries can benefit from Zig Zag Marketing\'s services?',
            answer: `Our services can benefit a wide range of businesses and industries, particularly those seeking innovative and unconventional marketing strategies to differentiate themselves in competitive markets.`,
        },
        {
            question: 'How do I get started with Zig Zag Marketing to explore unconventional marketing strategies for my brand?',
            answer: `Getting started with Zig Zag Marketing is simple. Just reach out to us through our contact page, and we'll set up an initial consultation. During this consultation, we'll discuss your business goals, target audience, and budget to tailor a customized strategy that defies convention and helps your brand leave a lasting impression.`,
        },
        {
            question: 'What sets Zig Zag Marketing apart from other marketing agencies that offer traditional strategies?',
            answer: `Zig Zag Marketing distinguishes itself by specializing in unconventional, curiosity-sparking strategies. We break free from the mundane to help your brand stand out in unique ways, creating engagement that's impossible to ignore.`,
        },
    ];
    const faqHeadRef = useRef();
    const [faqHeadAnimated, setFaqHeadAnimated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setFaqHeadAnimated(true);
                observer.unobserve(faqHeadRef.current);
            }
        }, observerOptions);

        if (faqHeadRef.current) {
            observer.observe(faqHeadRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [isLoading]);

    useEffect(() => {
        // Simulate a loading delay for demonstration purposes
        setTimeout(() => {
            setIsLoading(false); // Set isLoading to false when everything is loaded
        }, 3000);
    }, []);
    return (
        <>
            {isLoading ? (
                <Loaders />
            ) : (
                <>
                    <Navbar />
                    <div div className='outer'>
                        <div className='general'>
                            <div className="info">
                                <div className="info-head">Zig Zag Marketing</div>
                                <div className="info-d">“Break free from the mundane and stand out in a crowded
                                    marketplace. Our zig zag strategies ignite curiosity, turning heads and fueling engagement.
                                    ”</div>
                                <div className="info-p">B Rich will create a platform of mixed marketing strategies which are custom tailored to products and services. This Marketing will give companies a unique edge in reaching its customers through various means to increase its revenue share. 
                                    when a company uses several in combination to deliver a multichannel message, consumers can see those messages on their preferred channels.

                                </div>

                            </div>
                            <div className="service-image">
                                <div className="background">
                                    <img className='slide-img' src={social} alt="" />

                                </div>

                            </div>



                        </div>
                        <div className='faq'>
                            <div className="inner-faq">
                                <div className="faq-head" ref={faqHeadRef}>
                                    <h2 className={`faq-h2 ${faqHeadAnimated ? 'animated' : ''}`}>FAQ’S</h2>
                                    <h3 className={`faq-h4 ${faqHeadAnimated ? 'animated' : ''}`}>Frequently asked questions</h3>
                                    <p className={`faq-h5 ${faqHeadAnimated ? 'animated' : ''}`}>Can’t find the answer you’re looking for? Fill the Contact Form Below, Our Team will reach out to you soon.</p>
                                </div>
                                <div className="accordian">
                                    <Accordian data={accordionItems} />
                                </div>
                            </div>
                        </div>
                        <Contactus where={"Zig Zag Marketing"} />
                    </div>
                    <Footer />
                </>
            )}
        </>


    )
}

export default ZigZag

// Process.js
import React, { useEffect, useRef, useState } from 'react';
import star from "../media/Star.svg";
import thumb from "../media/money.png";
import coin from "../media/coins.png";
import laptop from "../media/laptop.png";

const CProcess = () => {
  const step1Ref = useRef();
  const step2Ref = useRef();
  const step3Ref = useRef();
  const step4Ref = useRef();

  const [step1Animated, setStep1Animated] = useState(false);
  const [step2Animated, setStep2Animated] = useState(false);
  const [step3Animated, setStep3Animated] = useState(false);
  const [step4Animated, setStep4Animated] = useState(false);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer1 = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setStep1Animated(true);
        observer1.unobserve(step1Ref.current);
      }
    }, observerOptions);

    const observer2 = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setStep2Animated(true);
        observer2.unobserve(step2Ref.current);
      }
    }, observerOptions);

    const observer3 = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setStep3Animated(true);
        observer3.unobserve(step3Ref.current);
      }
    }, observerOptions);

    const observer4 = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setStep4Animated(true);
        observer4.unobserve(step4Ref.current);
      }
    }, observerOptions);

    if (step1Ref.current) {
      observer1.observe(step1Ref.current);
    }
    if (step2Ref.current) {
      observer2.observe(step2Ref.current);
    }
    if (step3Ref.current) {
      observer3.observe(step3Ref.current);
    }
    if (step4Ref.current) {
      observer4.observe(step4Ref.current);
    }

    return () => {
      observer1.disconnect();
      observer2.disconnect();
      observer3.disconnect();
      observer4.disconnect();
    };
  }, []);

  return (
    <div className='process section' id='process'>
      <div className="step1 step" ref={step1Ref}>
        <div className='heading-div'>
          <h2 className={`step1h2 ${step1Animated ? 'animated' : ''}`}>The Process Behind Our Marketing Magic 🪄</h2>
        </div>
        <div className="mission">
          <div className="desc">
            <h2 className={`h1 ${step1Animated ? 'animated' : ''}`}>MISSION 🎯</h2>
            <p className={`p1 ${step1Animated ? 'animated' : ''}`}>“Fueling a Future Where Creators Thrive & Their Impact Resonates”</p>
          </div>
          <div className="star">
            <img src={star} alt="" />
          </div>
        </div>
      </div>
      <div className="process">
        <div className="step1 step2" ref={step2Ref}>
          <div className="develop">
            <div className={`thumb ${step2Animated ? 'animated' : ''}`} >
              <img src={thumb} alt="" />
            </div>
            <div className="desc desc1">
              <h2 className={`h2 ${step2Animated ? 'animated' : ''}`}>Building 🧑🏼‍🏭</h2>
              <p className={`develop-p p2 ${step2Animated ? 'animated' : ''}`}>
              “Your passion our Mission”
              </p>
              <h5 className={`h25 ${step2Animated ? 'animated' : ''}`}>
              Turning your passion into profitable revenue and authentic brand connections.              </h5>
            </div>
          </div>
        </div>
      </div>
      <div className="process">
        <div className="step1 step3" ref={step3Ref}>
          <div className="design design1">
            <div className="desc desc2">
              <h2 className={`h3 ${step3Animated ? 'animated' : ''}`}>Branding 🤵🏻</h2>
              <p className={`develop-p p3 ${step3Animated ? 'animated' : ''}`}>
              “Giving purpose to your content”              </p>
              <h5 className={`h35 ${step3Animated ? 'animated' : ''}`}>
              We're aware that marketing alone won't suffice for you. The key to breaking free from stagnation's grip lies in our combination of innovative strategies and revenue generation. Our strategy involves enhancing your online presence, amplifying your reach, forming partnerships that reflect your essence, and unlocking the gateway to sought-after brand deals and impactful engagements.              </h5>
            </div>
            <div className="coins">
              <img src={coin} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="process">
        <div className="step1 step4" ref={step4Ref}>
          <div className="implement">
            <div className="laptop">
              <img src={laptop} alt="" />
            </div>
            <div className="desc">
              <h2 className={`h4 ${step4Animated ? 'animated' : ''}`}>Boosting⚡</h2>
              <p className={`develop-p p4 ${step4Animated ? 'animated' : ''}`}>
              “Enhancing the power of your Influence”              </p>
              <h5 className={`h45 ${step4Animated ? 'animated' : ''}`}>
              Our aim is to ignite the fire within you in a world full of untapped potential. B Rich is not limited to being just a service platform, it’s more than that. Let's team up to change the direction of your creative journey, converting neglected brilliance into the dynamic recognition and financial success you truly deserve. We provide a space where your creative ideas come to life and you can realize your potential.              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CProcess;

import {React, useState, useEffect} from 'react'
import brich from "../media/brich.svg";
import p9 from "../media/p9.svg";
import fb from "../media/fb.svg";
import li from "../media/li.svg";
import insta from "../media/insta.svg";
import yellowfb from "../media/yellowfb.svg";
import yellowli from "../media/yellowli.svg";
import yellowinsta from "../media/yellowinsta.svg";
import send from "../media/send.svg";
import x from "../media/x.svg";
import Submittedpopup from '../components/Submittedpopup';
import ThankYoupopup from '../components/ThankYoupopup';



const CFooter = () => {
  
  
  

  
  const handleIconHover = (event) => {
    const target = event.target;
    const iconName = target.getAttribute("data-icon");
    
    if (iconName) {
      switch (iconName) {
        case 'insta':
          target.src = yellowinsta;
          break;
        case 'li':
          target.src = yellowli;
          break;
        case 'fb':
          target.src = yellowfb;
          break;
          default:
            break;
          }
        }
  };

  const handleIconLeave = (event) => {
    const target = event.target;
    const iconName = target.getAttribute("data-icon");

    if (iconName) {
      switch (iconName) {
        case 'insta':
          target.src = insta;
          break;
          case 'li':
            target.src = li;
            break;
            case 'fb':
              target.src = fb;
          break;
          default:
            break;
          }
        }
  };
  const handleNavigation = (url) => {
    window.location.href = url; // Redirect to the specified URL
  };
  const [showPopup, setShowPopup] = useState(false);
  const [showThankYouPopup, setShowThankYouPopup] = useState(false);


  const handleRequestCallback = () => {
    setShowPopup(true);
  };

  const handleFormSubmit = () => {
    // You can add form validation and submission logic here.
    // For now, just set the showThankYouPopup to true.
    setShowThankYouPopup(true);
  };
  
  useEffect(() => {
    const html = document.getElementsByTagName('body')[0]
  
    if (showPopup) {
      html.classList.add('open')
    } else {
      html.classList.remove('open')
    }
    return () => {
      html.classList.remove('open')
    }
  }, [showPopup])



  return (
    <div className='footer section'>
      <div className="inner-foot">

        <div className="service-social">
          <div className='heading'>Services</div>
          <div className="services">

          <div className="first">
  <div className="a" onClick={() => handleNavigation('/creators/unlocking-brands-deal')}>
    Unlocking Brand Deals
  </div>
  <div className="a" onClick={() => handleNavigation('/creators/influence-amplification')}>
    Influence Amplification
  </div>
  <div className="a" onClick={() => handleNavigation('/creators/tailored-guidance')}>
    Tailored Guidance
  </div>
</div>
<div className="second">
  <div className="a" onClick={() => handleNavigation('/creators/strategic-partnerships')}>
    Strategic Partnerships
  </div>
  <div className="a" onClick={() => handleNavigation('/creators/authentic-engagement')}>
  Authentic Engagement
  </div>
  <div className="a" onClick={() => handleNavigation('/creators/analytics-and-insights')}>
    Analytics and Insights
  </div>
</div>
<div className="third">
  <div className="a" onClick={() => handleNavigation('/creators/monetization-strategies')}>
    Monetization Strategies
  </div>
  <div className="a" onClick={() => handleNavigation('/creators/reputation-enhancements')}>
    Reputation Enhancement
  </div>
  <div className="a" onClick={() => handleNavigation('/creators/safety-and-protection')}>
    Safety & Protection
  </div>
</div>

          </div>

        </div>
        <div className="social">
          <div className="us"> Contact us.</div>
          <div className="callback" onClick={handleRequestCallback}><img src={p9} alt="" />Request a Callback</div>
          <div className="icon">
            <a
              href="https://instagram.com/brich.in?igshid=MzRlODBiNWFlZA=="
              className="social-icon"
              onMouseEnter={handleIconHover}
              onMouseLeave={handleIconLeave}
            >
              <img src={insta} alt="Instagram" data-icon="insta" />
            </a>
            <a
              href="https://www.linkedin.com/company/b-rich/"
              className="social-icon"
              onMouseEnter={handleIconHover}
              onMouseLeave={handleIconLeave}
            >
              <img src={li} alt="LinkedIn" data-icon="li" />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61551967450079&mibextid=LQQJ4d"
              className="social-icon"
              onMouseEnter={handleIconHover}
              onMouseLeave={handleIconLeave}
            >
              <img src={fb} alt="Facebook" data-icon="fb" />
            </a>
          </div>
        </div>
         {/* ... Your existing code for services and social sections ... */}
         <div className="popup-container">
         {showPopup && <Submittedpopup onClose={() => setShowPopup(false)} handleFormSubmit={handleFormSubmit}  where={"Creators Homepage"} />}
        </div>

        <div className="brand" onClick={() => handleNavigation('/creators/')}>
          <img src={brich} alt="" />
          B RICH
        </div>

      </div>
    </div>
  )
}

export default CFooter

import { React, useRef, useState, useEffect } from 'react'
import "./Allservice.css"
import Accordian from '../Accordian/Accordian';
import Contactus from '../Contact us/Contactus';
import social from "../../media/digital.svg"
import Footer from '../footer/Footer';
import Navbar from '../Navbar/Navbar';
import Loaders from '../Loader';

const Digital = () => {
    const accordionItems = [
        {
            question: 'What is Digital Marketing, and why is it important for my brand or business?',
            answer: `Digital Marketing is a comprehensive strategy that uses digital channels to reach and engage target audiences in the interconnected digital landscape. It's important for your brand because it allows you to connect with your audience where they spend a significant amount of their time, which can result in increased brand visibility, engagement, and conversions.`,
        },
        {
            question: 'How does Digital Marketing differ from traditional marketing approaches?',
            answer: `Digital Marketing leverages digital channels such as websites, social media, email, and online advertising to reach and engage audiences. Unlike traditional marketing, it offers a wide range of precise targeting and data-driven measurement, allowing for more personalized and effective marketing strategies.`,
        },
        {
            question: 'What types of businesses or industries can benefit from Digital Marketing services?',
            answer: `Digital Marketing is versatile and can benefit a wide range of businesses and industries. It's particularly beneficial for those looking to expand their online presence, reach a broader audience, and maximize their online marketing efforts.`,
        },
        {
            question: 'How does data, creativity, and technology come together in Digital Marketing campaigns?',
            answer: `Digital Marketing is a strategic fusion of data, creativity, and technology. Data is used to understand audience behavior and preferences, while creativity is applied to craft compelling content and campaigns. Technology, such as marketing automation and data analytics, is used to optimize campaigns and maximize their impact.`,
        },
        {
            question: 'What can a brand expect after implementing Digital Marketing services?',
            answer: `Brands can expect increased online visibility, engagement, and conversions. The specific results may vary depending on the brand's goals, but Digital Marketing is known for its ability to deliver measurable results by leveraging data, creativity, and technology to reach and engage target audiences in the digital realm.`,
        },
    ];
     const faqHeadRef = useRef();
    const [faqHeadAnimated, setFaqHeadAnimated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setFaqHeadAnimated(true);
                observer.unobserve(faqHeadRef.current);
            }
        }, observerOptions);

        if (faqHeadRef.current) {
            observer.observe(faqHeadRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [isLoading]);

    useEffect(() => {
      // Simulate a loading delay for demonstration purposes
      setTimeout(() => {
        setIsLoading(false); // Set isLoading to false when everything is loaded
      }, 3000);
    }, []);
    return (
        <>
        {isLoading ? (
            <Loaders />
          ) : (
            <>
            <Navbar/>        <div div className='outer'>
            <div className='general'>
                <div className="info">
                    <div className="info-head">DIGITAL
MARKETING</div>
                    <div className="info-d">“Digital marketing serves as a fundamental tool for reaching and engaging target audiences in an interconnected digital realm.”</div>
                    <div className="info-p">It’s one of the old tools of marketing through which all social marketing concepts developed. Search engine optimization, keywords, analytics, social networking websites, app are all are part of Digital marketing. <br />
Brich will start the marketing campaign from keywords, then design the search engine optimization of the website and then create the Search engine marketing, analytics and then reshaping the existing marketing strategies to achieve the companies ROI.
</div>

                </div>
                <div className="service-image">
                    <div className="background">
                        <img className='slide-img' src={social} alt="" />

                    </div>

                </div>



            </div>
                <div className='faq'>
                    <div className="inner-faq">
                        <div className="faq-head" ref={faqHeadRef}>
                            <h2 className={`faq-h2 ${faqHeadAnimated ? 'animated' : ''}`}>FAQ’S</h2>
                            <h3 className={`faq-h4 ${faqHeadAnimated ? 'animated' : ''}`}>Frequently asked questions</h3>
                            <p className={`faq-h5 ${faqHeadAnimated ? 'animated' : ''}`}>Can’t find the answer you’re looking for? Fill the Contact Form Below, Our Team will reach out to you soon.</p>
                        </div>
                        <div className="accordian">
                            <Accordian data={accordionItems} />
                        </div>
                    </div>
                </div>
                <Contactus where={"DIGITAL MARKETING"}/>
        </div>
        <Footer/>
                </>
                )}
                </>
    )
}

export default Digital

import { React, useRef, useState, useEffect } from 'react'
import Accordian from '../../components/Accordian/Accordian';
import social from "../../media/analytic.svg"
import CContactus from '../CContactus';
import CNavbar from '../Cnavbar';
import CFooter from '../CFooter';
import Loaders from '../../components/Loader';

const Analytics = () => {
    const accordionItems = [
        {
            question: 'What are "Analytics and Insights," and how can they benefit creators, brands, and individuals in the digital landscape?',
            answer: `"Analytics and Insights" involve the use of robust analytics dashboards to gain a deeper understanding of your audience, track performance metrics, and uncover audience demographics and preferences. They benefit creators, brands, and individuals by providing data-driven insights that help craft content that resonates, captivates, and cultivates a loyal following.`,
        },
        {
            question: 'How do "Analytics and Insights" differ from standard analytics tools and platforms available online?',
            answer: `"Analytics and Insights" go beyond standard analytics tools by offering customized insights that cater to the specific goals and audience of each client. Our approach is personalized, providing a deeper and more relevant understanding of the audience.`,
        },
        {
            question: 'Who can benefit from "Analytics and Insights" services, and in what situations are they most valuable?',
            answer: `"Analytics and Insights" can benefit a wide range of clients, including creators, brands, and individuals looking to optimize their content strategy and audience engagement. They are most valuable when seeking to enhance content quality, audience engagement, and overall performance.`,
        },
        {
            question: 'What specific data and metrics can clients expect to access through "Analytics and Insights" services?',
            answer: `Clients can access a wide range of data, including audience demographics, content performance metrics, viewer engagement, and more. These insights help creators understand who their audience is, what they prefer, and how to improve their content strategy.`,
        },
        {
            question: 'How can data-driven insights from "Analytics and Insights" help in crafting content that resonates and captivates the audience?',
            answer: `Data-driven insights provide a clear understanding of audience preferences and behaviors. This enables creators to tailor their content to better match what their audience is looking for, ultimately leading to content that resonates, captivates, and builds a loyal following.`,
        },
    ];
    const faqHeadRef = useRef();
    const [faqHeadAnimated, setFaqHeadAnimated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setFaqHeadAnimated(true);
                observer.unobserve(faqHeadRef.current);
            }
        }, observerOptions);

        if (faqHeadRef.current) {
            observer.observe(faqHeadRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [isLoading]);

    useEffect(() => {
      // Simulate a loading delay for demonstration purposes
      setTimeout(() => {
        setIsLoading(false); // Set isLoading to false when everything is loaded
      }, 3000);
    }, []);
    return (
        <>
        {isLoading ? (
            <Loaders />
          ) : (
            <>
      <CNavbar/>        <div div className='outer'>
            <div className='general'>
                <div className="info">
                    <div className="info-head">Analytics 
and Insights</div>
                    <div className="info-d">“Our analytics dashboards empower creators with audience insights, performance metrics, and data-driven decisions, revealing audience demographics and preferences.”</div>
                    <div className="info-p">Welcome to 'Analytics and Insights,' where data becomes your creative ally. Our robust analytics dashboards provide content creators with a powerful lens into their audience. Track performance metrics, uncover audience demographics, and delve into their preferences. Armed with these data-driven insights, you'll craft content that resonates, captivates, and cultivates a loyal following.</div>

                </div>
                <div className="service-image">
                    <div className="background">
                        <img className='slide-img' src={social} alt="" />

                    </div>

                </div>



            </div>
            <div className='faq'>
                <div className="inner-faq">
                    <div className="faq-head" ref={faqHeadRef}>
                        <h2 className={`faq-h2 ${faqHeadAnimated ? 'animated' : ''}`}>FAQ’S</h2>
                        <h3 className={`faq-h4 ${faqHeadAnimated ? 'animated' : ''}`}>Frequently asked questions</h3>
                        <p className={`faq-h5 ${faqHeadAnimated ? 'animated' : ''}`}>Can’t find the answer you’re looking for? Fill the Contact Form Below, Our Team will reach out to you soon.</p>
                    </div>
                    <div className="accordian">
                        <Accordian data={accordionItems} />
                    </div>
                </div>
            </div>
            <CContactus where={"Analytics and Insights"}/>
        </div>
        <CFooter/>
                </>
                )}
                </>
    )
}

export default Analytics

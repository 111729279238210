import { React, useState, useEffect } from 'react';
import brich from "../../media/brich.svg";
import "./navbar.css";
import logo1 from "../../media/1.svg"
import logo2 from "../../media/2.svg"
import logo3 from "../../media/3.svg"
import logo4 from "../../media/4.svg"
import logo5 from "../../media/5.svg"
import logo6 from "../../media/6.svg"
import logo7 from "../../media/7.svg"
import logo8 from "../../media/8.svg"
import logo9 from "../../media/9.svg"
import menu from "../../media/menu.svg"
import line9 from "../../media/9line.svg"
import { Link } from 'react-router-dom';


const Navbar = () => {
    const [expandedDiv, setExpandedDiv] = useState(null);
    const [showServiceMenu, setShowServiceMenu] = useState(false);
    const [isRightNavbarOpen, setIsRightNavbarOpen] = useState(false);
    const [isServiceItemVisible, setIsServiceItemVisible] = useState(false);
    const [isServiceItemClicked, setIsServiceItemClicked] = useState(false);

    const handleServiceItemClick = () => {
        setIsServiceItemVisible(!isServiceItemVisible);
        setIsServiceItemClicked(!isServiceItemClicked);

    };
    useEffect(() => {
        const html = document.getElementsByTagName('body')[0]

        if (isRightNavbarOpen) {
            html.classList.add('lock-scroll')
        } else {
            html.classList.remove('lock-scroll')
        }
        return () => {
            html.classList.remove('lock-scroll')
        }
    }, [isRightNavbarOpen])


    const toggleRightNavbar = () => {
        setIsRightNavbarOpen(!isRightNavbarOpen);
    };



    const handleHover = (event, divId) => {
        const hoverEffect = event.target;
        const dataText = hoverEffect.getAttribute('data-text');
        const spanElement = hoverEffect.querySelector('span');

        if (spanElement) {
            spanElement.textContent = dataText;
            hoverEffect.classList.add('expanded');
        }

        if (divId === 'services') {
            setShowServiceMenu(true);
        } else {
            setShowServiceMenu(false);
        }
    };

    const handleMouseLeave = (event, divId) => {
        const hoverEffect = event.target;
        const originalText = hoverEffect.getAttribute('data-original-text');
        const spanElement = hoverEffect.querySelector('span');


        if (spanElement) {
            // setTimeout(() => {
            setExpandedDiv(null);
            spanElement.textContent = originalText;
            hoverEffect.classList.remove('expanded');
            // }, 3000);
        }

    };
    const handleServiceMenuHover = () => {
        setShowServiceMenu(true);
        // setExpandedDiv('services');
    };

    const handleServiceMenuLeft = () => {
        setShowServiceMenu(false);
        // setExpandedDiv(null); 
    }
    const handleServiceClick = () => {
        setShowServiceMenu(!showServiceMenu)
    }

    const handleNavigation = (url) => {
        window.location.href = url;
    };

    const handleHoverDiv = (url) => {
    
        // Open the URL in a new tab
        window.open(url, '_blank');
      };

    return (
        <div className='nav-wrapper' id='navbar'>
            <div className='Navbar'>
                <Link to={"/"} className="logos">
                    <div ><img className="logo" src={brich} alt="" /></div>
                    <div className="name">B Rich</div>
                </Link>
                <div className="nav-items">
                    <Link
                        to="/creators"
                        className={`hover-effect ${expandedDiv === 'contact' ? 'expanded' : ''}`}
                        data-text="I’m a Creator 🙋🏻"
                        data-original-text="I’m a Creator"
                        onMouseEnter={(event) => handleHover(event, 'contact')}
                        onMouseLeave={handleMouseLeave}
                    >
                        <span> I’m a Creator</span>
                    </Link>
                    <Link
                        to="/contact-us"
                        className={`hover-effect ${expandedDiv === 'services' ? 'expanded' : ''}`}
                        data-text="Contact Us 🫱🏽‍🫲🏼"
                        data-original-text="Contact Us"
                        onMouseEnter={(event) => handleHover(event, 'contact')}
                        onMouseLeave={handleMouseLeave}
                    >
                        <span>Contact Us</span>
                    </Link>
                    <div
                        className={`hover-effect ${expandedDiv === 'services' ? 'expanded' : ''}`}
                        data-text="Services 💎"
                        data-original-text="Services"
                        onMouseEnter={(event) => handleHover(event, 'services')}
                        onMouseLeave={handleMouseLeave}
                    >
                        <span>Services</span>
                    </div>
                    <div
                        onClick={() => handleHoverDiv('https://dashboard.brich.in/')}
                        className={`hover-effect ${expandedDiv === 'contact' ? 'expanded2' : ''}`}
                        data-text="Brand Dashboard 📊"
                        data-original-text="Brand Dashboard"
                        onMouseEnter={(event) => handleHover(event, 'contact')}
                        onMouseLeave={handleMouseLeave}
                        style={{ maxWidth: "169px", width: "100% !important" }}                    >
                        <span>Brand Dashboard</span>
                    </div>
                </div>
                <div className="tab-menu">
                    <Link to="/creators" className="expanded1">I’m a Creator 🙋🏻</Link>
                    <Link to="/contact-us" className="expanded1">Contact Us 🫱🏽‍🫲🏼</Link>
                    <div className="expanded1" onClick={handleServiceClick}>Services 💎</div>
                    <div onClick={() => handleHoverDiv('https://dashboard.brich.in/')} style={{maxWidth:"169px"}} className="expanded1">Brand Dashboard 📊</div>
                </div>
                {showServiceMenu && (
                    <div
                        className={`service-menu ${expandedDiv === 'services' ? '' : ''}`}
                        // onMouseEnter={(event) => handleHover(event, 'services')}
                        // onMouseLeave={handleMouseLeave}
                        onMouseEnter={handleServiceMenuHover}
                        onMouseLeave={handleServiceMenuLeft}
                    >


                        <div onClick={() => handleNavigation('/social-media-marketing')} className="servive-item">
                            <div> <img src={logo1} alt="" /> </div> Social Media Marketing
                        </div>
                        <div onClick={() => handleNavigation('/zig-zag-marketing')} className="servive-item">
                            <div> <img src={logo2} alt="" /> </div> Zig Zag Marketing
                        </div>
                        <div onClick={() => handleNavigation('/live-management')} className="servive-item">
                            <div> <img src={logo3} alt="" /> </div> Live Management
                        </div>
                        <div onClick={() => handleNavigation('/conceptual-marketing')} className="servive-item">
                            <div> <img src={logo4} alt="" /> </div> Conceptual Marketing
                        </div>
                        <div onClick={() => handleNavigation('/AI-marketing')} className="servive-item" style={{ width: "125px", justifyContent: "flex-start" }}>
                            <div> <img src={logo5} alt="" /> </div> AI Marketing
                        </div>
                        <div onClick={() => handleNavigation('/reach-enhancements')} className="servive-item">
                            <div> <img src={logo6} alt="" /> </div> Reach Enhancements
                        </div>
                        <div onClick={() => handleNavigation('/influencer-marketing')} className="servive-item">
                            <div> <img src={logo7} alt="" /> </div> Influencer Marketing
                        </div>
                        <div onClick={() => handleNavigation('/reputation-management')} className="servive-item">
                            <div> <img src={logo8} alt="" /> </div> Reputation Management
                        </div>
                        <div onClick={() => handleNavigation('/digital-marketing')} className="servive-item">
                            <div> <img src={logo9} alt="" /> </div> Digital Marketing
                        </div>

                    </div>
                )}
                <div className={`sliding-logo ${isRightNavbarOpen ? 'open' : ''}`} onClick={toggleRightNavbar}>
                    <img src={menu} alt="" />
                </div>
                <div className={`right-navbar ${isRightNavbarOpen ? 'open' : ''}`}>
                    <div className='right1'>
                        <img src={line9} alt="Menu" onClick={toggleRightNavbar} />
                    </div>
                    <div className="nav-item">
                        <div onClick={() => handleNavigation('/creators')}>🙋🏻  I’m a Creator </div>
                        <div onClick={() => handleNavigation('/contact-us')}>🫱🏽‍🫲🏼  Contact Us </div>
                        <div onClick={() => handleHoverDiv('https://dashboard.brich.in/')}> 📊 Brand Dashboard </div>
                        <div
                            className={`service-item ${isServiceItemVisible ? 'visible' : ''}`}
                            onClick={handleServiceItemClick}
                        >💎 Services </div>
                        {/* Add more list items as needed */}
                    </div>
                    {isServiceItemClicked && (
                        <div
                            className={`service-item`}
                        >
                            <div onClick={() => handleNavigation('/social-media-marketing')}> <div>  <img src={logo1} alt="" /> </div>Social Media Marketing</div>
                            <div onClick={() => handleNavigation('/zig-zag-marketing')}><div>   <img src={logo2} alt="" /> </div>Zig Zag Marketing</div>
                            <div onClick={() => handleNavigation('/live-management')}>
                                <img src={logo3} alt="" /> Live Management
                            </div>
                            <div onClick={() => handleNavigation('/conceptual-marketing')}>
                                <img src={logo4} alt="" /> Conceptual Marketing
                            </div>
                            <div onClick={() => handleNavigation('/AI-marketing')}>
                                <img src={logo5} alt="" /> AI Marketing
                            </div>
                            <div onClick={() => handleNavigation('/reach-enhancements')}>
                                <img src={logo6} alt="" /> Reach Enhancements
                            </div>
                            <div onClick={() => handleNavigation('/influencer-marketing')}>
                                <img src={logo7} alt="" /> Influencer Marketing
                            </div>
                            <div onClick={() => handleNavigation('/reputation-management')}>
                                <img src={logo8} alt="" /> Reputation Management
                            </div>
                            <div onClick={() => handleNavigation('/digital-marketing')}>
                                <img src={logo9} alt="" /> Digital Marketing
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>

    );
};

export default Navbar;


//   const handleHover = (event) => {
//     const hoverEffect = event.target;
//     const dataText = hoverEffect.getAttribute('data-text');
//     hoverEffect.querySelector('span').textContent = dataText;
//   };

//   const handleMouseLeave = (event) => {
//     const hoverEffect = event.target;
//     const originalText = hoverEffect.getAttribute('data-original-text');
//     hoverEffect.querySelector('span').textContent = originalText;
//   };
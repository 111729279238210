import React from 'react';
import "./Homepage/homepage.css"
import logobig from "../media/logobig.svg"


const Loaders = () => {
  return (
    <div className="loader" style={{ zIndex: "4" }}>
      <div className="logo-big">
        <div className="forlogo">

        <img src={logobig} alt="" />
        </div>
        <h2>B Rich</h2>
      </div>

     
      </div>
      );
}

      export default Loaders;

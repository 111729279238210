import React, { useState, useEffect, useRef } from 'react';
import "./contactus.css";
import chat from "../../media/Contact.png";
import send from "../../media/send.svg";
import ThankYoupopup from '../ThankYoupopup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const Contactus = (props) => {
  const [showThankYouPopup, setShowThankYouPopup] = useState(false);

  const initialValues = {
    company_name: '',
    send_from: props.where,
    nature_of_business: '',
    address: '',
    postcode: '',
    phone_number: '',
    email: '',
  };

  const validationSchema = Yup.object().shape({
    company_name: Yup.string().required('Company name is required'),
    nature_of_business: Yup.string().required('Nature of business is required'),
    address: Yup.string().required('Address is required'),
    postcode: Yup.string().required('Postcode is required'),
    phone_number: Yup.string().required('Phone number is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
  });

  const contactHeadRef = useRef();
  const [contactHeadAnimated, setContactHeadAnimated] = useState(false);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.8,
    };

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setContactHeadAnimated(true);
        observer.unobserve(contactHeadRef.current);
      }
    }, observerOptions);

    if (contactHeadRef.current) {
      observer.observe(contactHeadRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className='contactus section' id='contactus'>
      <div className="contact-head" ref={contactHeadRef}>
        <h2 className={`contactus-h2 ${contactHeadAnimated ? 'animated' : ''}`}>Contact US</h2>
        <div className="line"></div>
        <div className='contact-right'>
          <h3 className={`contactus-h4 ${contactHeadAnimated ? 'animated' : ''}`}>Get in Touch, </h3>
          <h4 className={`contactus-h5 ${contactHeadAnimated ? 'animated' : ''}`}>Your Success Awaits.</h4>
        </div>
      </div>
      <div className="con">
        <div className="con-left">
          <img src={chat} alt="" />
        </div>
        <div className="con-right">
          <div className="contact-form-wrapper">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={async (values, { resetForm, setSubmitting, setFieldError }) => {
                try {
                  const response = await fetch('https://api.creator.ind.in/api/contact-us/', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(values),
                  });

                  if (response.status === 201) {
                    resetForm();
                    setShowThankYouPopup(true);
                  } else {
                    // Handle specific error cases (e.g., validation errors from the API)
                    const data = await response.json();
                    if (data.errors) {
                      // For example, if the API returns errors as { errors: { field_name: 'error message' } }
                      Object.keys(data.errors).forEach((fieldName) => {
                        setFieldError(fieldName, data.errors[fieldName]);
                      });
                    }
                  }
                } catch (error) {
                  console.error('Error submitting form:', error);
                } finally {
                  setSubmitting(false);
                }
              }}
            >
              <Form>
                <div className="custom-input">
                  <Field type="text" name="company_name" placeholder="Your Company Name" />
                  <ErrorMessage name="company_name" component="div" className="error-message" />
                </div>

                <div className="custom-input">
                  <Field type="text" name="nature_of_business" placeholder="Nature of Business" />
                  <ErrorMessage name="nature_of_business" component="div" className="error-message" />
                </div>

                <div className="address">
                  <div className="custom-input">
                    <Field type="text" name="address" placeholder="Address" />
                    <ErrorMessage name="address" component="div" className="error-message" />
                  </div>
                  <div className="custom-input" style={{ width: "151px" }}>
                    <Field type="number" name="postcode" placeholder="Postcode" />
                    <ErrorMessage name="postcode" component="div" className="error-message error-message1"  />
                  </div>
                </div>

                <div className="custom-input">
                  <Field type="number" name="phone_number" placeholder="Contact Number" />
                  <ErrorMessage name="phone_number" component="div" className="error-message" />
                </div>

                <div className="custom-input">
                  <Field type="email" name="email" placeholder="Email" />
                  <ErrorMessage name="email" component="div" className="error-message" />
                </div>

                <button variant="dark" type="submit" className='submit-btn send-btn'>Send <img src={send} alt="" className="send" /></button>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      {showThankYouPopup && <ThankYoupopup onClose={() => setShowThankYouPopup(false)} />}
    </div>
  );
};

export default Contactus;

import React, { useState, useEffect, useRef } from 'react';
import "./faq.css"
import Accordian from '../Accordian/Accordian'

const Faq = ({style}) => {
        
    const accordionItems = [
      {
          question: 'What sets B Rich apart from other marketing agencies?',
          answer: `At B Rich, we're not just marketers; we're innovators. Our unique blend of conceptual 
          strategies, cutting-edge technology, and dynamic approaches differentiate us. 
          We challenge the norm, infusing creativity into every campaign to maximize 
          your brand's potential.`,
      },
      {
          question: 'How can B Rich help my brand overcome stagnant growth?',
          answer: `Our services are tailored to break through growth barriers. Through innovative
          messaging, targeted campaigns, and live interactions, we revitalize engagement
          and rekindle growth where it matters most.`,
      },
      {
          question: `Can B Rich enhance my brand's online reputation`,
          answer: `Absolutely. Our comprehensive reputation management strategies not only protect
          your brand from negative feedback but also proactively shape a positive online
          presence, building trust and credibility.`,
      },
      {
          question: ' What benefits does influencer marketing offer my brand?',
          answer: `Influencer marketing amplifies your brand's reach through authentic connections.
          We collaborate with influencers aligned with your values, fostering real engagement
          and driving meaningful results.`,
      },
      {
          question: ' What benefits does influencer marketing offer my brand?',
          answer: `Influencer marketing amplifies your brand's reach through authentic connections.
          We collaborate with influencers aligned with your values, fostering real engagement
          and driving meaningful results.`,
      },
  ];
    
    const faqHeadRef = useRef();
    const [faqHeadAnimated, setFaqHeadAnimated] = useState(false);
  
    useEffect(() => {
      const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.6,
      };
  
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setFaqHeadAnimated(true);
          observer.unobserve(faqHeadRef.current);
        }
      }, observerOptions);
  
      if (faqHeadRef.current) {
        observer.observe(faqHeadRef.current);
      }
  
      return () => {
        observer.disconnect();
      };
    }, []);
  
    return (
      <div className='faq section' id='faq' style={style}>
        <div className="inner-faq">
          <div className="faq-head" ref={faqHeadRef}>
            <h2 className={`faq-h2 ${faqHeadAnimated ? 'animated' : ''}`}>FAQ’S</h2>
            <h3 className={`faq-h4 ${faqHeadAnimated ? 'animated' : ''}`}>Frequently asked questions</h3>
            <p className={`faq-h5 ${faqHeadAnimated ? 'animated' : ''}`}>Everything you need to know about B Rich.</p>
          </div>
          <div className="accordian">
            <Accordian data={accordionItems} />
          </div>
        </div>
      </div>
    );
  }
  
  export default Faq;
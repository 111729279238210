import styled from 'styled-components'; 

export const Heading = styled.h1` 
text-align: center; 
color: #B88D00; 
`; 

export const Content = styled.div` 
overflowY: scroll; 
height: 2500px; 
`; 

export const Button = styled.div` 
position: fixed; 
right: 45px;
bottom: 66px;
height: 20px; 
font-size: 3rem; 
z-index: 1; 
cursor: pointer; 
color: #B88D00;
`

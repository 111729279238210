import {React, useState} from 'react'
import x from "../media/x.svg";

const ThankYoupopup = ({ onClose }) => {
  return (
    <div className="popup popup1">
    <div className="popup-content content1 content2">
      <div onClick={onClose} className="cross">
        <img src={x} alt="" />
      </div>
      <h2>YOUR REQUEST 
HAS BEEN SUBMITED</h2>
      {/* Add your "Thank you" message content here */}
    </div>
  </div>
  )
}

export default ThankYoupopup

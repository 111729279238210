import React, { useState, useEffect, useRef } from 'react';
import chat from "../media/Contact.png";
import send from "../media/send.svg";
import ThankYoupopup from '../components/ThankYoupopup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const CContactus = (props) => {
  const [showThankYouPopup, setShowThankYouPopup] = useState(false);

  const initialValues = {
    name: '',
    send_from: props.where,
    phone_number: '',
    email: '',
    needs_description: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phone_number: Yup.string().required('Phone number is required'),
    needs_description: Yup.string().required('Description is required'),
  });

  const contactHeadRef = useRef();
  const [contactHeadAnimated, setContactHeadAnimated] = useState(false);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.8,
    };

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setContactHeadAnimated(true);
        observer.unobserve(contactHeadRef.current);
      }
    }, observerOptions);

    if (contactHeadRef.current) {
      observer.observe(contactHeadRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className='contactus section' id='contactus'>
      <div className="contact-head" ref={contactHeadRef}>
        <h2 className={`contactus-h2 ${contactHeadAnimated ? 'animated' : ''}`}>Contact US</h2>
        <div className="line"></div>
        <div className='contact-right'>
          <h3 className={`contactus-h4 ${contactHeadAnimated ? 'animated' : ''}`}>Get in Touch</h3>
          <h4 className={`contactus-h5 ${contactHeadAnimated ? 'animated' : ''}`}>Your Success Awaits</h4>
        </div>
      </div>
      <div className="con">
        <div className="con-left">
          <img src={chat} alt="" />
        </div>
        <div className="con-right">
          <div className="contact-form-wrapper">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={async (values, { resetForm, setSubmitting, setFieldError }) => {
                try {
                  const response = await fetch('https://api.creator.ind.in/api/creators/', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(values),
                  });

                  if (response.status === 201) {
                    resetForm();
                    setShowThankYouPopup(true);
                  } else {
                    if (response.status === 400) {
                      const data = await response.json();
                      if (data.errors) {
                        Object.keys(data.errors).forEach((fieldName) => {
                          setFieldError(fieldName, data.errors[fieldName]);
                        });
                      }
                    }
                  }
                } catch (error) {
                  console.error('Error submitting form:', error);
                } finally {
                  setSubmitting(false);
                }
              }}
            >
              <Form>
                <div className="custom-input">
                  <Field type="text" name="name" placeholder="Your Name" />
                  <ErrorMessage name="name" component="div" className="error-message" />
                </div>
                <div className="custom-input">
                  <Field type="email" name="email" placeholder="Email" />
                  <ErrorMessage name="email" component="div" className="error-message" />
                </div>
                <div className="custom-input">
                  <Field type="number" name="phone_number" placeholder="Contact Phone" />
                  <ErrorMessage name="phone_number" component="div" className="error-message" />
                </div>
                <div className="custom-input">
                  <Field type="text" name="needs_description" placeholder="Say Something About Yourself" />
                  <ErrorMessage name="needs_description" component="div" className="error-message" />
                </div>
                <button variant="dark" type="submit" className='submit-btn send-btn'>Send <img src={send} alt="" className="send" /></button>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      {showThankYouPopup && <ThankYoupopup onClose={() => setShowThankYouPopup(false)} />}
    </div>
  );
};

export default CContactus;

import { React, useRef, useState, useEffect } from 'react'
import Accordian from '../../components/Accordian/Accordian';
import social from "../../media/tailor.svg"
import CContactus from '../CContactus';
import CNavbar from '../Cnavbar';
import CFooter from '../CFooter';
import Loaders from '../../components/Loader';

const Tailored = () => {
    const accordionItems = [
        {
            question: 'What is "Tailored Guidance," and how can it benefit brands, creators, and individuals in the digital landscape?',
            answer: `"Tailored Guidance" involves crafting personalized strategies that align with your unique aspirations and brand identity, helping you navigate the complexities of the digital landscape. It benefits brands, creators, and individuals by providing them with customized assistance that enables them to seize opportunities and overcome challenges confidently.`,
        },
        {
            question: 'How does "Tailored Guidance" differ from one-size-fits-all approaches in digital strategies?',
            answer: `"Tailored Guidance" is distinct from generic strategies because it is personalized to your specific goals, values, and brand identity. We take the time to understand your unique path and craft strategies that align with your aspirations, ensuring a more customized and effective approach.`,
        },
        {
            question: 'Who can benefit from "Tailored Guidance" services, and in what situations is it most valuable?',
            answer: `"Tailored Guidance" is valuable for a wide range of clients, including brands, creators, and individuals looking to excel in the digital landscape. It is particularly beneficial in situations where a one-size-fits-all approach is not sufficient, such as when navigating complex market landscapes or working to stand out in a competitive digital world.`,
        },
        {
            question: 'How is personalized guidance provided in "Tailored Guidance" services?',
            answer: `Personalized guidance is provided through consultations, in-depth assessments, and a deep understanding of your brand's values and goals. We collaborate closely with you to create strategies that not only fit your unique path but also empower you to excel in the digital landscape.`,
        },
        {
            question: 'What can clients expect from "Tailored Guidance" in terms of their digital journey and success?',
            answer: `Clients can expect to receive strategies that are in perfect alignment with their aspirations, brand identity, and digital landscape challenges. "Tailored Guidance" empowers clients to navigate their digital journey with confidence, seize opportunities with precision, and ultimately achieve digital excellence.`,
        },
    ];
     const faqHeadRef = useRef();
    const [faqHeadAnimated, setFaqHeadAnimated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setFaqHeadAnimated(true);
                observer.unobserve(faqHeadRef.current);
            }
        }, observerOptions);

        if (faqHeadRef.current) {
            observer.observe(faqHeadRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [isLoading]);

    useEffect(() => {
      // Simulate a loading delay for demonstration purposes
      setTimeout(() => {
        setIsLoading(false); // Set isLoading to false when everything is loaded
      }, 3000);
    }, []);
    return (
        <>
        {isLoading ? (
            <Loaders />
          ) : (
            <>
      <CNavbar/>        <div div className='outer'>
            <div className='general'>
                <div className="info">
                    <div className="info-head">Tailored Guidance</div>
                    <div className="info-d">“Your journey, our guidance. We craft strategies that align with 
your aspirations and unique brand, helping you navigate the challenges and 
opportunities of the digital landscape.”</div>
                    <div className="info-p">Discover 'Tailored Guidance,' where your unique journey meets our expert strategies. We understand that every path in the digital landscape is distinctive. Our mission is to align our guidance with your aspirations and brand identity, enabling you to navigate challenges with confidence and seize opportunities with precision. Your success story begins here, where personalized strategies pave the way to digital excellence.</div>

                </div>
                <div className="service-image">
                    <div className="background">
                        <img className='slide-img' src={social} alt="" />

                    </div>

                </div>



            </div>
            <div className='faq'>
                <div className="inner-faq">
                    <div className="faq-head" ref={faqHeadRef}>
                        <h2 className={`faq-h2 ${faqHeadAnimated ? 'animated' : ''}`}>FAQ’S</h2>
                        <h3 className={`faq-h4 ${faqHeadAnimated ? 'animated' : ''}`}>Frequently asked questions</h3>
                        <p className={`faq-h5 ${faqHeadAnimated ? 'animated' : ''}`}>Can’t find the answer you’re looking for? Fill the Contact Form Below, Our Team will reach out to you soon.</p>
                    </div>
                    <div className="accordian">
                        <Accordian data={accordionItems} />
                    </div>
                </div>
            </div>
            <CContactus where={"Tailored Guidance"} />
        </div>
        <CFooter/>
                </>
                )}
                </>
    )
}

export default Tailored

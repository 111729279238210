import { React, useRef, useState, useEffect } from 'react'
import Accordian from '../../components/Accordian/Accordian';
import social from "../../media/brand.svg"
import CContactus from '../CContactus';
import CNavbar from '../Cnavbar';
import CFooter from '../CFooter';
import Loaders from '../../components/Loader';

const BrandDeal = () => {
    const accordionItems = [
        {
            question: 'What does "Unlocking Brand Deals" entail, and how can it benefit creators or influencers?',
            answer: `"Unlocking Brand Deals" involves simplifying the world of brand collaborations and connecting creators with brands that align with their vision and values. It benefits creators by helping them secure rewarding partnerships that not only reflect their true worth but also enhance their influence and visibility.`,
        },
        {
            question: 'How does your service bridge the gap between creators and brands?',
            answer: `We bridge the gap by leveraging our extensive network and expertise in the industry. We identify brands that align with a creator's vision and values and facilitate meaningful connections through strategic collaboration.`,
        },
        {
            question: 'What types of creators or influencers can benefit from "Unlocking Brand Deals" services?',
            answer: `"Unlocking Brand Deals" can benefit a wide range of creators, including influencers, content creators, artists, and more. It's especially valuable for those looking to expand their brand collaborations and secure deals that reflect their worth.`,
        },
        {
            question: 'What role does negotiation play in securing rewarding brand deals for creators?',
            answer: `Negotiation is a critical component of our service. Our seasoned negotiators work tirelessly to ensure that creators secure deals that not only match their worth but also align with their goals and vision. Negotiation helps maximize the benefits of the collaboration.`,
        },
        {
            question: 'What can creators expect after utilizing "Unlocking Brand Deals" services?',
            answer: `Creators can expect the opportunity to secure brand collaborations that are in line with their values and vision. This often leads to increased visibility, credibility, and influence in the market, creating new possibilities for growth and recognition.`,
        },
    ];
     const faqHeadRef = useRef();
    const [faqHeadAnimated, setFaqHeadAnimated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setFaqHeadAnimated(true);
                observer.unobserve(faqHeadRef.current);
            }
        }, observerOptions);

        if (faqHeadRef.current) {
            observer.observe(faqHeadRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [isLoading]);

    useEffect(() => {
      // Simulate a loading delay for demonstration purposes
      setTimeout(() => {
        setIsLoading(false); // Set isLoading to false when everything is loaded
      }, 3000);
    }, []);
    return (
        <>
        {isLoading ? (
            <Loaders />
          ) : (
            <>
      <CNavbar/>        <div div className='outer'>
            <div className='general'>
                <div className="info">
                    <div className="info-head">Unlocking Brand Deals</div>
                    <div className="info-d">“Navigating the world of brand collaborations can be complex. 
At B Rich, we bridge the gap, connecting you with the brands that align with your 
vision and values. Our expertise in negotiation ensures you secure deals that reflect your worth and amplify your influence.”</div>
                    <div className="info-p">Welcome to the realm of 'Unlocking Brand Deals,' where we simplify the intricate world of brand collaborations. At B Rich, we specialize in connecting you with brands that resonate with your vision and values. Our seasoned negotiators work tirelessly to secure deals that not only reflect your true worth but also magnify your influence in the market. Get ready to unlock the doors to rewarding partnerships and endless possibilities.</div>

                </div>
                <div className="service-image">
                    <div className="background">
                        <img className='slide-img' src={social} alt="" />

                    </div>

                </div>



            </div>
            <div className='faq'>
                <div className="inner-faq">
                    <div className="faq-head" ref={faqHeadRef}>
                        <h2 className={`faq-h2 ${faqHeadAnimated ? 'animated' : ''}`}>FAQ’S</h2>
                        <h3 className={`faq-h4 ${faqHeadAnimated ? 'animated' : ''}`}>Frequently asked questions</h3>
                        <p className={`faq-h5 ${faqHeadAnimated ? 'animated' : ''}`}>Can’t find the answer you’re looking for? Fill the Contact Form Below, Our Team will reach out to you soon.</p>
                    </div>
                    <div className="accordian">
                        <Accordian data={accordionItems} />
                    </div>
                </div>
            </div>
            <CContactus where={"Unlocking Brand Deals"}  />
        </div>
        <CFooter/>
                </>
                )}
                </>
    )
}

export default BrandDeal

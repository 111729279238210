import { React, useRef, useState, useEffect } from 'react'
import Accordian from '../../components/Accordian/Accordian';
import social from "../../media/repu.svg"
import CContactus from '../CContactus';
import CNavbar from '../Cnavbar';
import CFooter from '../CFooter';
import Loaders from '../../components/Loader';

const Reputatione = () => {
    const accordionItems = [
        {
            question: 'What is "Reputation Enhancement" for creators and influencers, and how can it benefit me in the digital landscape?',
            answer: `"Reputation Enhancement" for creators and influencers involves the meticulous rebuilding of trust and credibility in the digital realm. It benefits you by ensuring your online presence accurately reflects your true value and potential, helping you gain recognition and credibility in the digital world.`,
        },
        {
            question: 'How does "Reputation Enhancement" specifically cater to creators and influencers compared to traditional reputation management efforts?',
            answer: `"Reputation Enhancement" for creators and influencers focuses on shaping your digital identity to accurately reflect your true worth. It ensures your online presence fosters trust and credibility, which is essential for establishing authority and influence in your niche.`,
        },
        {
            question: 'Who can benefit from "Reputation Enhancement" services among creators and influencers, and when is it most valuable?',
            answer: `"Reputation Enhancement" is valuable for creators and influencers looking to rebuild trust and credibility in the digital realm. It is especially valuable when you face reputation challenges or seek to align your online presence with your true value as an influential content creator.`,
        },
        {
            question: 'What specific strategies are employed in "Reputation Enhancement" to help creators and influencers rebuild trust and credibility?',
            answer: `Strategies may include online reputation monitoring, content optimization, review management, and proactive engagement with your audience. These strategies work together to ensure that your digital identity authentically represents your value and potential.`,
        },
        {
            question: 'How can "Reputation Enhancement" help creators and influencers step into a realm of renewed trust and credibility, reflecting their exceptional value in the digital landscape?',
            answer: `"Reputation Enhancement" empowers creators and influencers to rebuild trust and credibility by ensuring their online presence accurately reflects their true worth. This fosters recognition and credibility, positioning them as authoritative and influential figures in their niche.`,
        },
    ];
     const faqHeadRef = useRef();
    const [faqHeadAnimated, setFaqHeadAnimated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setFaqHeadAnimated(true);
                observer.unobserve(faqHeadRef.current);
            }
        }, observerOptions);

        if (faqHeadRef.current) {
            observer.observe(faqHeadRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [isLoading]);

    useEffect(() => {
        // Simulate a loading delay for demonstration purposes
        setTimeout(() => {
            setIsLoading(false); // Set isLoading to false when everything is loaded
        }, 3000);
    }, []);
    return (
        <>
            {isLoading ? (
                <Loaders />
            ) : (
                <>
                    <CNavbar />        <div div className='outer'>
                        <div className='general'>
                            <div className="info">
                                <div className="info-head">Reputation Enhancement </div>
                                <div className="info-d">“Shape your digital identity. Our reputation management
                                    services rebuild trust and credibility, ensuring your online presence reflects your true value and potential.
                                    ”</div>
                                <div className="info-p">Welcome to 'Reputation Enhancement,' where your digital identity takes center stage. Our reputation management services are designed to meticulously rebuild trust and credibility in the digital realm. We work tirelessly to ensure your online presence authentically represents your true worth and potential. Step into a realm of renewed trust and credibility, where your reputation reflects the exceptional value you bring to the world.</div>

                            </div>
                            <div className="service-image">
                                <div className="background">
                                    <img className='slide-img' src={social} alt="" />

                                </div>

                            </div>



                        </div>
                        <div className='faq'>
                            <div className="inner-faq">
                                <div className="faq-head" ref={faqHeadRef}>
                                    <h2 className={`faq-h2 ${faqHeadAnimated ? 'animated' : ''}`}>FAQ’S</h2>
                                    <h3 className={`faq-h4 ${faqHeadAnimated ? 'animated' : ''}`}>Frequently asked questions</h3>
                                    <p className={`faq-h5 ${faqHeadAnimated ? 'animated' : ''}`}>Can’t find the answer you’re looking for? Fill the Contact Form Below, Our Team will reach out to you soon.</p>
                                </div>
                                <div className="accordian">
                                    <Accordian data={accordionItems} />
                                </div>
                            </div>
                        </div>
                        <CContactus where={"Reputation Enhancement"} />
                    </div>
                    <CFooter />
                </>
            )}
        </>
    )
}

export default Reputatione
